@import '/setting';
body{
  // Table Component UI Code
  .export-dropdown{
    cursor: pointer;
    font-size: 12px;
    font-family: $ibmplexRegular;
    img.export-icn{
      width: 18px;
      margin-right: 5px;
    }
  }
  .export-dropdown-menu{
    .menu-item{
      padding: 5px 5px;
      border-bottom: 1px solid #EAEAEA;
      cursor: pointer;
      font-size: 12px;
      font-family: $ibmplexRegular;
      &:hover{
        background: #f1f1f1;
      }
      &:last-child{
        border-bottom: none;
      }
      img{
        width: 17px;
        margin-right: 6px;
      }
    }
  }
  .per-page-menu{
    min-width: 40px;
    text-align: center;
    cursor: pointer;
    .per-page-option{
      &:hover{
        background: #f1f1f1;
        cursor: pointer;
      }
    }
  }

  // Table Component UI Code @extend
  // Application SideBar Code
  .for-label{
    font-size: 12px;
    font-family: $ibmplexRegular;
    color: $labelGrey;
    display: block;
  }
  .application-edit-side-bar, .inline-editable-forms{
    .react-datepicker-wrapper, .react-datepicker__input-container{
      width: 100% !important;
    }
    .add-component{
      font-size: 14px;
      color: #2f8cfe;
      cursor: pointer;
    }
    input[type="text"], input[type="email"], input[type="number"], textarea{
        border-bottom: 1px solid $labelGrey;
        width: 100%;
    }
    .cover-photo{
      width: 360px;
      height: 360px;
      margin-bottom: 100px;
    }
    .lopa-photo{
      width: 200px;
      height: 200px;
      margin-bottom: 40px;
    }
    .edit-bar-content-blk{
      position: fixed;
      top:0;
      right: 0px;
      width:470px;
      height: 100vh;
      background: #fff;
      z-index: 1000;
      box-shadow:0 2px 4px 0 rgba(0,0,0,0.50);
      transition:all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      max-width: 100%;
      .top-content{
        position: relative;
        padding: 15px 20px;
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 20px;
        color: #9439e4;
        h3{
          font-size: 20px;
          font-family: $ibmplexRegular;
          color: #22345D;
          position: relative;
        }
        p{
          font-size: 12px;
          font-family: $ibmplexRegular;
          color: #675E73;
        }
        .close-sidebar{
          position: absolute;
          top: 40%;
          right: 15px;
          cursor: pointer;
        }
      }
      .body-content{
        position: relative;
        .introduction-editor{
          height: 520px;
          .cke_contents{
            height: 330px!important;
          }
        }
        .review-editor{
          height: 520px;
          .remove-review-section{
            color: red;
            font-size: 12px;
            font-family: $ibmplexRegular;
            margin: 5px;
          }
          .collapse-editor{
            position: relative;
            background: #f1efef;
            padding: 2px;
            border-radius: 5px;
            border: 1px solid #d3d3d3;
            margin-bottom: 10px;
            .collapse-header{
              position: relative;
              padding: 5px;
              .fa-angle-down{
                &::before{
                  position: absolute;
                  right:0;
                  top:50%;
                  transform: translate(-50%, -50%);
                }
              }
              .review-title, .review-index{
                border-bottom:1px solid #d3d3d3;

              }
              .review-index{
                width: 6%;
                margin-right: 10px;
              }
              .review-title{
                width: 90%;
              }
              a{
                color: #d3d3d3;
                float: right;
                font-size: 24px;
                padding-right: 5px;
                font-family: $ibmplexRegular;
              }
            }
          }
        }
        .sidebar-form{
            overflow-y: auto;
            padding: 0 20px 60px;
            min-height: 101%;
          &::-webkit-scrollbar {
            -webkit-appearance: none !important;

          }
          &::-webkit-scrollbar:vertical {
            width: 11px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid white;
            background-color: rgba(0, 0, 0, .5);
          }

          .escalation-block{
            background-color: rgb(247, 247, 247);
            margin: 0px;
            padding: 15px;
            .escalation-heading{
              // margin: 15px;
              font-family: $ibmplexRegular;
              font-size: 14px;
              color: #691ad3;
              position: relative;
              width:100%;
              text-align: right;
              a{
                font-family: $ibmplexRegular;
                font-size: 12px;
                color: #2f8cff;
                // right:0;
                // position: absolute;
                &.add-attachment{
                  width: 116px;
                }
                input[type="file"]{
                  position: absolute;
                  right: 0;
                  width: 116px;
                  opacity: 0;
                }
              }
            }
            table{
              width:100%;
              margin-top:15px;
              th{
                padding: 8px 15px;
              }
              tbody{
                tr{
                  &:nth-child(even){
                    background: #fff;
                  }
                  &:nth-child(odd){
                    background: #edf0f3;

                  }
                  td{
                    font-family: $ibmplexRegular;
                    font-size: 14px;
                    color: #000000;
                    input{
                      background: transparent;

                    }
                    img{
                      display: inline-block;
                      margin: 0 5px;
                      &.profile-pic{
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                      }
                    }
                  }
                }
              }
            }
          }
          .cover-photo-block{
            width:100%;
            position: relative;
             .cover-photo-hover{
                display: block;
                background: rgba(0,0,0,.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                transition: opacity 0.2s ease;
                b{
                  font-size: 16px;
                  font-family: Conv_IBMPlexSans-Regular;
                  color: #fff;
                  display: block;
                  width: 100%;
                  margin: 10px 0;
                  cursor: pointer;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  display: inline-block;
                  width: 150px;
                  z-index: 9;
                  img{
                    display: inline-block;
                    margin-right: 10px;
                  }
                  input[type="file"]{
                    cursor: pointer;
                    opacity: 0;
                    width: 150px;
                    position: absolute;
                    left: 0;
                    right: 0;

                  }
                }

              }
            &:hover{
              .cover-photo-hover{
                opacity: 1;

              }
            }
          }

          .lopa-photo-block{
            position: relative;
             .lopa-photo-hover{
                display: block;
                background: rgba(0,0,0,.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                z-index: 9;
                transition: opacity 0.2s ease;
                b{
                  font-size: 16px;
                  font-family: Conv_IBMPlexSans-Regular;
                  color: #fff;
                  display: block;
                  width: 100%;
                  margin: 10px 0;
                  cursor: pointer;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  display: inline-block;
                  width: 150px;
                  z-index: 9;
                  img{
                    display: inline-block;
                    margin-right: 10px;
                  }
                  input[type="file"]{
                    cursor: pointer;
                    opacity: 0;
                    width: 150px;
                    position: absolute;
                    left: 0;
                    right: 0;

                  }
                }

              }
            &:hover{
              .lopa-photo-hover{
                opacity: 1;

              }
            }
          }




          thead{
            th{
              background: #ebebeb;
              font-size: 12px;
              color: #8c9ba5;
              padding: 4px 15px;
              font-family: $ibmplexRegular;
              z-index: 99;
              top: -1px;
            }

          }
          tbody{
            tr{
              border-bottom: 1px solid #ddd;
              padding:6px;
              td{
                font-size: 12px;
                color: #8c9ba5;
                padding: 8px 15px;
                font-family: $ibmplexRegular;
              }
              &:hover{
                background: #f3f7ff;
              }
            }

          }
          .grp-arrow{
            position: absolute;
            top: 18px;
            right: 0;
            width: 16px;
            color: #007eff;
            padding: 10px 30px;
            font-size: 10px;
            display: inline-block;
            cursor: pointer;
            img{
              width: 16px;
            }
          }
          ul.filter-list{
            max-height:250px;
            overflow: auto;
            &::-webkit-scrollbar{
              display: none;
            }
            border: 1px solid #dfe3e6;
            border-top:1px solid #dfe3e6;
            background: #fff;
            width: 560px;
            position: absolute;
            top: 60px;
            z-index: 2;
            box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
            li{
              cursor: pointer;
              font-family: $ibmplexRegular;
              font-size: 12px;
              padding:10px 15px;
              border-bottom:1px solid #dfe3e6;
              transition: all 0.5s ease;
              &:last-child{
                border-bottom:none;
              }
              &:hover{
                background: #f5f5f5;
              }
            }
          }
        }

      }
      .edit-sidebar-footer{
        padding: 15px 22px;
        background: #f0f2f3;
        position: absolute;
  			bottom: 0;
  			left:0;
  			right:0;
        width: 100%;
        transition: all .5s cubic-bezier(.075,.82,.165,1);
        .add-eng-to-forms{
          input[type="checkbox"]{
            width: 20px!important;
            margin: 0px 10px 5px!important;
          }
          span{
            font-size: 13px;
          }
        }
        ul{
          li{
            margin-right: 20px;
            button,a{
              font-size: 14px;
              font-family: $ibmplexRegular;
              color: $linkBlue;
  						cursor: pointer;
  						&:hover{
  							color: $linkBlue;
  						}
            }
            button[type="submit"]{
              @extend %primary-botton;
            }
            .import-picture{
              color: $linkBlue;
              background: transparent;
              &:disabled{
                cursor: not-allowed;
              }
              img{

  							width: 12px;
  							margin-right: 3px;
  							position: relative;
  							top: -2px;
              }
            }
            .delete-picture{
              color: $red;
              background: transparent;
              &:disabled{
                cursor: not-allowed;
  						}
  						&:hover{
  							color: $red;
  						}
              img{
  							height: 14px;
  							margin-right: 3px;
  							object-fit: contain;
  							position: relative;
  							top: -1px;
              }
            }
          }
        }
      }

      .sidebar-table{
        border: 1px solid #eaeaea;
        width:100%;
        thead{
          th{
            font-size: 12px;
            font-family: $ibmplexMedium;
            padding: 10px;
            color: #898989;
          }
        }
        tbody{
          td{
            padding: 10px 15px 8px !important;
            position: relative;
            h3{
              font-size: 14px;
              font-family: $ibmplexSemiBold;
              color: #000;
              img{
                width: 9px;
                margin-right: 5px;
                position: relative;
                top: -1px;
              }
            }
            p{
              font-size: 12px;
              font-family: $ibmplexRegular;
            }
            input[type="checkbox"]{
              cursor: pointer;
            }
          }
        }
      }
      .seat-techspec{
        ul{
          li{
            position: relative;
            margin-bottom:24px;
            img{
              width: 18px;
              right: 10px;
              position: absolute;
            }
            b{
              width: 18px;
              height: 18px;
              background-color: #ebebeb;
              border-radius: 50%;
              right: 10px;
              position: absolute;
            }
          }
        }
      }

      .faq-accordian{
        padding: 0 0 15px;
        .react-accordion-with-header{
          box-shadow: none !important;
          >div{
            margin-bottom: 15px;
            border-bottom: 1px solid #f1f1f1;
            &:last-child{
              border-bottom: none;
            }
            >div{
              box-shadow: none !important;
              padding: 0 10px !important;
              font-size: 12px;
              color: #a1a1a1;
              text-align: left;
              padding: 5px 0 6px !important;
              .fa{
                  float: right;
              }
              .fa-chevron-up{
                display: none;
              }
            }
            .header-is-expanded{
              border-bottom: none;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              .fa-chevron-down{
                display: none;
              }
              .fa-chevron-up{
                display: inline-block;
              }
            }
            .is-expanded{
              border-top: none;
              border-radius: 4px;
              border-top-left-radius: 0;
              border-top-right-radius : 0;
              font-family: $ibmplexRegular;
              font-size: 12px;
              color: #a1a1a1;
              text-align: left;
              max-height: 100% !important;
              padding: 10px 0 !important;
            }
          }
        }
        h2{
          font-size: 14px;
          color: #2f8cfe;
          text-align: left;
          display: block;
          width: 100%;
        }
        p{
          font-size: 12px;
          color: #a1a1a1;
          text-align: left;
        }
      }

    }
    .overlays{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      background: rgba(0,0,0,0.4);
    }

  }
  // Application SideBar Code End
}
// sticky header stylr
.table-height {
	display: block;
	overflow: auto;
  width: 100%;
  &::-webkit-scrollbar{
    display: none;
  }
  .no-records-found{
    padding: 22px 0px;
    margin:0px;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    &.table-sticky{
      margin-bottom:0;
      >thead{
        >tr{
          >th, >td{
            top: -1px;
            position: sticky;
            z-index: 1;
          }
        }
      }
    }
  }
}
