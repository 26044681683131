@import '../../../../assets/styles/setting.scss';
body{
  .SearchFilter{
    h6{
      font-size: 14px;
    }
    .filterContainer{
      background: #fff;
      padding: 25px;
      margin-bottom: 20px;
      margin-top: 10px;
      border-radius: 3px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
      display: flex;
      .filterField{
        display: inline-block;
        width: 150px;
        margin-right: 20px;
        label{
          color: #959292;
          font-size: 14px;
          font-weight: lighter;
        }
        .react-datepicker-wrapper{
          border-bottom: 1px solid #aaa;
        }
        .primary-btn{
          display: block;
        }
        a{
          color: #2f8cff;
          display: block;
          font-size: 13px;
          padding-top: 5px;
          margin-left: 10px;
        }
      }
    }
  }
  .global-search-page{
    margin-left: 70px;
    margin-top: 30px;
    .global-search-result-info{
      margin-bottom: 20px;
      p{
        font-size: 16px;
        margin-bottom: 10px;
        font-family: $ibmplexRegular;
        img{
          width: 20px;
          margin-right: 2px;
        }
      }
      h2{
        font-size: 26px;
        font-family: $ibmplexLight;
        margin-bottom: 5px;
        span{
          font-family: $ibmplexMedium;
        }
      }
      h6{
        font-size: 14px;
        font-family: $ibmplexRegular;
      }
    }
    .search-result-container{
      background: #fff;
      min-height: 75vh;
      padding: 5px 15px;
      .pagination{
        margin-top: 35px;
      }
      .nav-tabs{
        margin-bottom: 15px;
        .nav-item{
          .nav-link{
            background: transparent;
            border: 0;
            cursor: pointer;
            border-color: transparent;
            font-family:$ibmplexRegular;
            font-size: 14px;
            &.active{
             color: #2f8cff ;
             border-bottom: 1px solid #2f8cff ;
           }
         }
       }
     }
     .record-search-list{
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 15px;
        margin-bottom: 15px;
        img{
          float: left;
          margin-top: 5px;
          width: 20px;
        }
        .records-info{
          margin-left: 30px;
          .msn-tag{
            border: 1px solid #e1e1e1;
            padding: 2px 12px;
            font-size: 12px;
            border-radius: 3px;
            display: inline-block;
          }
          h3{
            line-height: 18px;
            margin-bottom: 2px;
            a{
              font-size: 14px;
              color: #000;
              font-family: $ibmplexMedium;
              &:hover{
                color: #4789ff;
              }
              .SearchHighlightClass{
                background: #a0e4a0;
              }
            }
          }
          p{
            font-size: 12px;
            color: #a1a1a1;
            font-family: $ibmplexRegular;
          }
          ul{
            margin-top: 3px;
            li{
              font-size: 12px;
              color: #000;
              border-right: 1px solid #d7d7d7;
              padding-right: 15px;
              padding-left: 15px;
              margin: 0;
              &:first-child{
                padding-left: 0px;
              }
              &:last-child{
                border-right: none
              }
              span{
                color: #a1a1a1 !important;
                padding-right: 4px;
                display: inline-block;
              }
            }
          }
        }
      }
      h1{
        img{
          width: 20px;
          margin-right: 5px;
          cursor: pointer;
        }
        font-size: 24px;
        padding: 10px;
        margin-bottom: 10px;
      }
      .no-records-found{
        width: 100%;
      }
    }
  }
}
