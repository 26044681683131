@import '../../../../assets/styles/setting';
.hl-filter-block{
  label{
    margin-bottom:0 !important;
    color: #5596e6;
  }
  p{
    color: #5596e6;
  }
  .react-datepicker-wrapper{
    border-bottom:1px solid #ddd;
  }
}

.half-life-maintenance{
    background: #fafafa;
    border: 1px solid #d4d0d0;
    h4{
      font-family:$ibmplexRegular;
      font-size:16px;
      &.util-heading{
        color:#5596e6;
      }
      &.event-heading{
        color:#bd10e0;

      }
      img{
        float:right;
      }
    }

    .llp-breakdown-modal{
      color:#5596e6 !important;
      font-size: 10px;
      font-family:$ibmplexRegular;
    }

    .util-outer-wrap{
      background: #f0f2f3;
      border-top: 1px solid #d4d0d0;
      padding:15px;
      .utilization-block{
        margin:0;
        padding-top: 15px;
      }
    }

    .event-outer-wrap{
      background: #fff;
      padding:15px;
      .event-block{
        margin:0;
        padding-top: 15px;
      }
    }





  .life-adj-block{
    background:#fafafa;
  }
  .adj-inner-block{
    margin-bottom: 15px;
    padding:0;
    h6{
      font-family:$ibmplexRegular;
      font-size: 12px;
      color: #a0a0a0;
    }
    span{
      font-family:$ibmplexRegular;
      font-size: 14px;
      color: $color_000;
      line-height: 20px;
      img{
        margin-left:4px;
        display: inline-block;
      }
    }
  }


}
/** modal css **/
.half-life-modal{
  widows: 85%;
  max-width: 85%;
  .modal-header{
    span.engine-name{
      color: #000;
      font-size: 14px;
      position: relative;
      top: -4px;
      font-family: $ibmplexRegular;
      display: block;
    }
    .as-of-date{
      color: #000;
      font-size: 12px;
      position: relative;
      top: -4px;
      font-family: $ibmplexRegular;
    }
  }
  table{
    width:98% !important;
  }

}




// xsmall devices
@media (max-width: 575.98px) {
  .hl-filter-block{
    margin-bottom:15px;

  }
  .half-life-maintenance{
     margin: 0 6px 15px;
  }
}


// Large devices (desktops, 992px and up)

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hl-filter-block{
    margin:0 15px;

  }
  .half-life-maintenance{
    margin: 0 15px;
    .util-outer-wrap{
      border-right: 1px solid #d4d0d0;

    }
    .event-outer-wrap{
      border-right: 1px solid #d4d0d0;

    }
  }
}


// Extra large devices (large desktops, 1200px and up)

@media (min-width: 1200px) {
  .hl-filter-block{
    margin:0 15px;

  }
  .half-life-maintenance{
    margin: 0 15px;
    margin-left: 60px;
    .util-outer-wrap{
      border-right: 1px solid #d4d0d0;
    }
    .event-outer-wrap{
      border-right: 1px solid #d4d0d0;

    }
  }
}
