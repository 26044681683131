@import '../../../../assets/styles/setting';
body{
  font-family:$ibmplexRegular;
  .coverPhotoContainer{
    width: 160px;
    display: block;
    padding-bottom: 40px;
    &:hover{
      .coverPhotoActions{
        display: block;
      }
    }
    .coverPhotoActions{
      display: none;
      background: #000;
      opacity: 0.4;
      width: 160px;
      height: 160px;
      position: absolute;
      top: 25px;
      ul{
        text-align: center;
        font-size: 14px;
        margin-top: 50px;
        li{
          list-style: none;
          color: #fff;
          cursor: pointer;
        }
      }
      &:hover{
        ul{
          display: block;
        }
      }
    }
  }
  .warning-info-text{
    font-size: 10px;
    width: fit-content;
    display: block;
    background: #fff1b6;
    padding: 2px 5px;
    color: #a78701;
    border: 1px solid #a78701;
    border-radius: 4px;
  }
  .data-room-expansion-loader{
    position: fixed;
    bottom: 5px;
    padding-left: 35%;
    img{
      width: 20px;
    }
  }
  .data-room-expansion-loader-db{
    position: relative;
    bottom: 5px;
    padding-left: 45%;
    top:50px;
    img{
      position: absolute;
      width: 30px;
    }
  }
  .ai-support-info{
    height: 380px;
    background: #fff;
    z-index: 999;
    width: 320px;
    position: absolute;
    box-shadow:0px 4px 4px 2px rgba(0,0,0,.2);
    -webkit-transition-timing-function: cubic-bezier(0,0,0,1);
    transition-timing-function: cubic-bezier(0,0,0,1);
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    margin-left: 5px;
    .ai-support-heading{
      color: #8c9ba4;
      padding: 0px 10px;
      display: block;
      margin-top: 10px;
      font-size: 12px;
    }
    .ai-support-body{
      height: 220px;
      overflow: auto;
      // &::-webkit-scrollbar{
      //   display: none;
      // }
      margin: 10px 10px 0px 10px;
      border-bottom: 1px solid rgba(0,0,0,0.2);
      // &::-webkit-scrollbar{
      //   display: none;
      // }
      .ai-support-folder{
        font-size: 14px;
        margin-top: 10px;
        display: block;
        border-bottom: 0.3px solid rgba(0,0,0,0.2);
        padding-bottom: 5px;
        font-family:$ibmplexSemiBold;
        &:last-child{
          border-bottom: none;
        }
      }
    }
    .ai-support-footer{
      font-size: 10px;
      padding-top: 5px;
      div{
        padding: 5px 20px;
        text-align: center;
        color: #2f8cfd;
        &:last-child{
          border-left: 1px solid rgba(0,0,0,0.2);
        }
      }
    }
  }
  .notice-info{
    font-size: 11px;
    margin-left: 25px;
    margin-bottom: 10px;
    background: #fff1b6;
    padding: 2px 5px;
    color: #a78701;
    border: 1px solid #a78701;
    border-radius: 4px;
    span{
      font-family: $ibmplexSemiBold;
    }
    .caution-img{
      height: 10px;
      margin-bottom: 5px;
      margin-right: 3px;
      margin-top: 2px;
    }
    .h6{
      color:#a78701;
      font-size: 11px;
    }
    a{
      float: right;
    }
  }
  .navigation-side-bar{
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    background: #ffffff;
    position: fixed;
    z-index: 99;
    left: 270px;
    top: 146px;
    transition: all .5s cubic-bezier(.075,.82,.165,1);
    border-right: 1px solid #d7d7d7;
    .folder-tree-navigation{
      padding-bottom: 20px;
      .rc-tree-node-content-wrapper{
        font-size: 12px;
        color: #000000;
        font-family: $ibmplexRegular;
        .rc-tree-title{
          padding-left: 5px;
          position: relative;
          top: 2px;
        }
        .rc-tree-iconEle{
          color: #c6cddd;
          font-size: 14px;
          padding-top: 2px;
        }
      }
      .rc-tree-node-selected{
        background: none!important;
        border: none!important;
        opacity: 1!important;
        .rc-tree-title{
          color: #000!important;
        }
      }
      @keyframes spinner {
        to {transform: rotate(360deg);}
      }
      .rc-tree-treenode-loading{
        .rc-tree-title:before{
          content: '';
          box-sizing: border-box;
          position: absolute;
          top: 5px;
          left: -16px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border-top: 2px solid #07d;
          border-right: 2px solid transparent;
          animation: spinner .6s linear infinite;
        }
      }
    }
  }
  .modal{
    .btn-success{
      margin-top: 15px;
    }
    .modal-body{
      input[type="text"]{
        width: 100%;
        border-bottom: 1px solid #d3d3d3;
      }
      #file_url{
        width: 84%;
      }
    }
  }
  .tab-left{
    border-bottom:1px solid #ddd;
    margin-bottom:25px !important;
    li{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:#8c9ba5 !important;
      text-align:center !important;
      padding: 0px 15px;
      cursor: pointer;
      .download-action-dock{
        position: absolute;
        top: 130px;
        right: 20px;
        li{
          padding: 0px;
          span{
            position: relative;
            top: 0px;
            right: 0px;
          }
        }
      }
    }
    .active{
      border-bottom: 2px solid #1c86ff;
    }
  }
  .download-block{
    button{
      background: transparent;
      border: none;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:$color_cff;
      &:focus, &:hover, &:active, &.active{
        box-shadow: 0 0 0 !important;
        background: transparent!important;
        color: #2f8cff!important;
      }
      span{
        margin-right:2px;
        img{
          margin-right: 2px;
          position: relative;
          top: -1px;
        }
      }
      &.dropdown-toggle::after{
        position: relative;
        top: 1px;
      }
    }
    .dropdown-menu{
      background:$color_fff;;
      border:1px solid #e9e9e9;
      box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
      border-radius:3px;
      padding:10px;
      .dropdown-item{
        cursor: pointer;
        padding: 0 0 10px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;
        &:last-child{
          border-bottom: none;
          margin-bottom:0;
          padding-bottom:0;
        }
        img{
          width:12px;
          margin-right:10px;
        }
      }
    }
  }
  .bucket-action{
    font-size: 12px;
    span,a{
      cursor: pointer;
      padding: 5px;
      display: inline-block;
    }
  }
  .filter-list-wrapper{
    position: fixed;
    background: #fff;
    z-index: 1000;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    height: 100vh;
    top: 0;
    transition: all .5s cubic-bezier(.075,.82,.165,1);
    width: 470px;
    right: 0px;
    .filter-navigation{
      .selectAll{
        cursor: pointer;
        float: right;
        font-size: 12px;
        color: #0077ff;
      }
    }
    .filter-heading{
      h4{
        margin: 28px 15px 28px 25px;
        position: relative;
        font-family: Conv_IBMPlexSans-Regular;
        font-size: 16px;
        color: #9439e4;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 11px;
        top: 0;
        span{
          background: #d3d3d3;
          border-radius: 50% 50% 50% 50%;
          padding: 5px 8px;
          color: #fff;
          font-size: 10px;
          cursor: pointer;
          float: right;
        }
      }
    }
    .filter-list-wrap{
      padding: 0px 25px;
      max-height: 400px;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .record-list{
        display: block;
        word-break: break-all;
        &:hover,.active{
          background: #E8F0FC;
        }
        cursor: pointer;
        border: 1px solid #dfe3e6;
        border-top: none;
        border-left: none;
        border-right: none;
        line-height: 40px;
        padding-left: 15px;
        background: #fff;
        span{
          font-size:12px;
          color:#8c9ba5;
          text-align:left;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .record-list:last-child{
        margin-bottom: 20px;
      }
    }
    .filter-list-footer{
      background: #fff;
      padding: 10px 25px;
      position: absolute;
      bottom: 5px;
      box-shadow: 0 -2px 2px 0px rgba(0,0,0,.61);
      width: 100%;
    }
  }
  .file-info{
    position: fixed;
    top:110px;
    right:0px;
    bottom: 0;
    z-index: 9;
    background-color: #4a4a4a;
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0, 1);
    transition-timing-function: cubic-bezier(0, 0, 0, 1);
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    .file-info-header{
      position: fixed;
      width: 750px;
      z-index: 9;
      background-image:linear-gradient(0deg, #2a282b 1%, #4a4a4a 100%);
      height:30px;
      .close-file-info{
        background-color: #fff;
        border-radius: 50%;
        height: 20px;
        width: 50px;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: 720px;
        top: 3px;
        cursor: pointer;
      }
      img{
        cursor:pointer;
      }
    }
    .file-preview{
      margin-top: 20px;
      margin-left: 10px;
      background:#ffffff;
      box-shadow:0 2px 12px 0 rgba(0,0,0,0.18);
      position: relative;
      height: 450px;
      width:530px;
      top: 30px;
      object{
        width: 100%
      }
    }
    .file-details{
      width: 330px;
      height: 450px;
      position: relative;
      top: 30px;
      margin: 15px auto;
      .file-info-inner{
        background: #555454;
        display: block;
        border-radius: 3px;
        width: 730px;
        height: 150px;
        margin-top: 20px;
        margin-left: 10px;
        padding-left: 30px;
        padding-top: 20px;
        table{
          color: #ffffff;
          font-family:$ibmplexRegular;
          tbody{
            tr td{
              font-size: 12px;
            }
            td:nth-child(1){
              text-align: right;
              width:30%;
              padding-right: 5px;
            }
            td:nth-child(2){
              text-align: left;
              width:70%;
            }
          }
        }
      }
    }
  }
  .upload-progress{
    height: 15px;
    margin-top:25px;
    span{
      font-size: 10px;
    }
  }
  .filter-block{
    .form-check-input{
      margin-left: 0px;
    }
  }
  .asset-list,.list-wrapper{
    .rejected-action{
      font-family:$ibmplexRegular;
      font-size:12px;
      padding: 8px 8px;
      border-radius: 5px;
      background: #f5c4c4;
      color: #c34747;
    }
    .rename-action{
      font-family:$ibmplexRegular;
      font-size:12px;
      padding: 8px 10px 10px 10px;
      border-radius: 5px;
      background: #FFF3DD;
      img{
        width: 10px;
        margin: -1px 3px;
        cursor: pointer;
      }
    }
    .record-preview{
      span:hover{
        color: #2789ff;
      }
    }
  }
  .file-navigation{
    position: fixed;
    top: 95px;
    height: 50px;
    left: 240px;
    right: 0px;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    &:active{
      box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    }
    .active{
      text-decoration: underline;
      text-decoration-color: #a8a8a8;
      text-decoration-style: solid;
    }
    .navigation-scroll{
      white-space: nowrap;
      overflow-x: scroll;
      padding-left: 10px;
      padding-bottom: 25px;
      .navigation-item{
        display: inline;
        padding-left: 5px;
        .file-path{
          font-family:$ibmplexRegular;
          font-size:15px;
          color:#8c9ba5;
          text-align:left;
          position: relative;
          left: 15px;
          top: 10px;
        }
      }
    }
    .bucket-link{
      position: relative;
      right: 20px;
      margin-right: 10px;
      top: 15px;
      color: #308efe;
      font-size: 14px;
      img{
        width: 20px;
      }
    }
    .bucket-count{
      position: relative;
      left: 13px;
      top: 0px;
      font-size: 10px;
    }
    .inventory-link{
      position: relative;
      right: 40px;
      top: 18px;
      float: right;
      color: #0C59B0;
      font-size: 12px;
      img{
        width: 15px;
        margin-right: 5px;
        margin-bottom: 3px;
      }
    }
  }
  .draggable{
    height: 200px;
    position: fixed;
    width: 79%;
    bottom: 10px;
    left: 250px;
    z-index: 999;
    img{
      width: 60px;
      position: relative;
      left: 45%;
    }
    span{
      top: 70px;
      position: relative;
      left: 30%;
      font-size: 14px;
      background: #3368FF;
      color: #ffffff;
      padding: 20px 50px;
      -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.5);
      box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.5);
    }
  }
  .upload-file{
    transition: all 0.02s  cubic-bezier(0, 0, 0, 1);
    position: relative;
    z-index: 999;

    .dragEvent{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,0.7);
      justify-content: center;
      z-index: 999;
      p{
        font-size: 14px;
        color:#fff;
      }
      img{
        margin: 0 auto 15px;
        display: block;
      }
    }
  }
  .file-upload-list{
    position: fixed;
    right: 10px;
    bottom: 10px;
    min-height: 80px;
    max-width: 400px;
    max-height: 400px;
    font-size: 14px;
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.5);
    .file-upload-list-header{
      padding: 10px;
      color: #ffffff;
      background: #000;
      span{
        float:right;
        cursor: pointer;
      }
    }
    .file-upload-list-body{
      background: #ffffff;
      color: #8c9ba5;
      min-height: 38px;
      max-height: 360px;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      ul{
        list-style: none;
        li{
          padding: 5px 10px;
          border-bottom: 1px solid #f1f1f1
        }
      }
    }
  }
  .records-list-wrap{
    .dataroom-sort-block{
      margin-bottom: 5px;
      margin-top: -15px;
      span{
        font-size: 12px;
      }
      .inner-columns{
        padding-right: 10px;
        padding-top: 5px;
        text-align: right;
      }
      .inner-columns-select{
        padding-left: 0px;
        padding-right: 20px;
      }
      .custom-select-block{
        height: 35px;
        background-color: transparent;
        display: inline-block;
        width: 100%;
        .Select-placeholder{
          font-size: 12px!important;
        }
        .Select-value{
          padding-left: 0!important ;
          font-size: 14px!important;
          .Select-value-label{
            margin-left:0 !important;
          }
          background-color: #eef0f1;
        }
      }
    }
    .record-activity-table{
      table{
        width: 100%;
        border: none;
        thead{
          background: #F7F8FA;
          border-bottom: 1px solid #C6CDDD;
          th{
            font-style: $ibmplexRegular;
            color: #3D70B2;
            font-size: 14px;
            padding: 10px 8px;
          }
          .Select-multi-value-wrapper{
            position: initial!important;
            .Select-value{
              position: initial!important;
              margin-bottom: -20px;
              .Select-value-label{
                position: initial!important;
              }
            }
            .Select-input{
              height: 0px;
            }
          }
          .Select-clear-zone{
            .Select-clear{
              position: initial!important;
            }
          }
          .Select-arrow-zone{
            .Select-arrow{
              position: initial!important;
            }
          }
        }
        tbody{
          background: #ffffff;
          padding: 0 0px;
          tr{
            table-layout: auto;
            width: 100%;
            border-bottom: 1px solid #F5F7FA;
            cursor: pointer;
            &:hover{
              background: #f7f8fa;
            }
            &:last-child{
              border-bottom: none;
            }
            td{
              border: none;
              font-style: $ibmplexRegular;
              font-size: 12px;
              color: #000;
              padding: 10px 8px;
              img.profile-pic{
                width: 35px;
                height: 35px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    h1{
      font-family:$ibmplexRegular;
      font-size:20px;
      color:#000000;
      margin-bottom:24px;
      margin-left: 15px;
    }
    margin-left: 265px;
    margin-top: 30px;
    .data-bucket-heading{
      font-family: $ibmplexRegular;
      font-size: 20px;
      color: #bd10e0;
      padding-bottom: 20px;
      margin-left: 15px;
    }
    .list-wrapper{
      -webkit-transition-timing-function: cubic-bezier(0, 0, 0, 1);
    	transition-timing-function: cubic-bezier(0, 0, 0, 1);
    	-webkit-transition-duration: .2s;
      transition-duration: .2s;
      // margin: 25px 15px 0 15px;
      &.file_bucket_block{
        .no-records-found{
          margin:0 15px;
        }
      }
      .no-records-found{
        margin:0;
      }
      .record-list{
        margin:0 5px;
        display: block;
        &:hover,.active{
          background: #E8F0FC;
        }
        cursor: pointer;
        border: 1px solid #dfe3e6;
        border-top: none;
        line-height: 40px;
        padding-left: 15px;
        background: #fff;
        span{
          font-size:12px;
          color:#8c9ba5;
          text-align:left;
          padding-left: 10px;
          padding-right: 10px;
          word-break: break-all;
        }
      }
      .record-list:first-child{
        border-top: 1px solid #dfe3e6;
      }
      .record-list:last-child{
        margin-bottom: 20px;
      }
    }
    .folder-info{
      position: fixed;
      top:95px;
      right:0px;
      bottom: 0;
      z-index: 9;
      background-color: #4a4a4a;
      -webkit-transition-timing-function: cubic-bezier(0, 0, 0, 1);
    	transition-timing-function: cubic-bezier(0, 0, 0, 1);
    	-webkit-transition-duration: .2s;
    	transition-duration: .2s;
      .close-folder-info{
        background-color: #fff;
        border-radius: 50%;
        height: 20px;
        width: 50px;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: 520px;
        top: 5px;
        cursor: pointer;
      }
      .folder-details{
        width: 400px;
        height: 250px;
        top: 70px;
        position: relative;
        margin: 0 auto;
        img{
          position: relative;
          left: 45%;
        }
        .folder-title{
          font-family:$ibmplexRegular;
          color: #ffffff;
          text-align: center;
          h4{
            font-size: 14px;
            display: block;
            margin-top: 10px;
          }
        }
        .folder-info-inner{
          background: #555454;
          display: block;
          border-radius: 3px;
          width: 400px;
          height: 180px;
          margin-top: 20px;
          margin-left: 10px;
          padding-left: 30px;
          padding-top: 20px;
          table{
            width: 100%;
            color: #ffffff;
            font-family:$ibmplexRegular;
            tbody{
              tr{
                td{
                  font-size: 12px;
                }
                td:nth-child(1){
                  text-align: right;
                  width:35%;
                  padding-right: 5px;
                }
                td:nth-child(2){
                  text-align: left;
                  width:65%;
                }
              }
            }
          }
        }
        button{
          font-family:$ibmplexRegular;
          font-size:12px;
          color:#ffffff;
          background-color: transparent;
          text-align:center;
          border:1px solid #ffffff;
          border-radius:3px;
          width:85px;
          height:30px;
          margin-top: 20px;
          margin-left: 35%;
        }
      }
    }
    .file-info{
      position: fixed;
      top:95px;
      right:0px;
      bottom: 0;
      z-index: 9;
      background-color: #4a4a4a;
      -webkit-transition-timing-function: cubic-bezier(0, 0, 0, 1);
    	transition-timing-function: cubic-bezier(0, 0, 0, 1);
    	-webkit-transition-duration: .2s;
    	transition-duration: .2s;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .file-info-header{
        background-image:linear-gradient(0deg, #2a282b 1%, #4a4a4a 100%);
        height:30px;
        .close-file-info{
          background-color: #fff;
          border-radius: 50%;
          height: 20px;
          width: 50px;
          padding-left: 5px;
          padding-right: 5px;
          position: relative;
          left: 720px;
          top: 3px;
          cursor: pointer;
        }
        img{
          cursor:pointer;
        }
      }
      .file-preview{
        margin-top: 20px;
        margin-left: 10px;
        background:#ffffff;
        box-shadow:0 2px 12px 0 rgba(0,0,0,0.18);
        position: relative;
        height: 450px;
        width:730px;
        object{
          width: 100%
        }
      }
      .file-details{
        width: 400px;
        height: 250px;
        position: relative;
        margin: 15px auto;
        .file-info-inner{
          background: #555454;
          display: block;
          border-radius: 3px;
          width: 400px;
          height: 180px;
          margin-top: 20px;
          margin-left: 10px;
          padding-left: 30px;
          padding-top: 20px;
          table{
            color: #ffffff;
            font-family:$ibmplexRegular;
            tbody{
              tr td{
                font-size: 12px;
              }
              td:nth-child(1){
                text-align: right;
                width:30%;
              }
              td:nth-child(2){
                text-align: left;
                width:70%;
              }
            }
          }
        }
      }
    }
  }
  .react-contexify{
    .react-contexify__item{
      .react-contexify__item__data{
        font-size: 12px;
      }
    }
    .remove-item{
      .react-contexify__item__data{
        color:#ff5e5e;
      }
    }
  }
  .share-record-modal{
    font-family:$ibmplexRegular;
    background:#ffffff;
    border:1px solid #e4e4e4;
    box-shadow:0 2px 8px 0 rgba(47,140,255,0.74);
    border-radius:3px;
    width:650px;
    margin-top: 10%;
    .modal-content{
      .no-records-found{
        z-index: 1051;
      }
      border: none;
      h2{
        font-size: 14px;
        margin-top: 15px;
        span{
          font-family: $ibmplexBold;
        }
        a{
          float: right;
          font-size: 12px;
          color: #017bff;
        }
      }
      .modal-body{
        .share-link-checkbox{
          margin-left: 5px;
          margin-bottom: 15px;
          font-size: 14px;
        }
        span{
          font-size: 12px;
          color: #000;
          display: inline-block;
          line-height: 18px;
        }
        .list-inline{
          margin-top: 5px;
          .active{
            border-color: #3497ff!important;
            color: #3497ff!important;
          }
          .list-inline-item{
            padding: 10px 18px;
            font-size: 12px;
            border: 1px solid #f1f1f1;
            border-radius: 5px;
            input[type="radio"]{
              margin-right: 5px;
            }
            input[type = "number"]{
              width: 40px;
              border: 1px solid #f2f2f2;
              margin-left: 5px;
              border-radius: 5px;
            }
            span{
              color: #000;
            }
            select{
              border: 1px solid #f2f2f2;
              margin-left: 5px;
              width: 50px;
              border-radius: 5px;
              padding: 2px;
              background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right transparent;
              background-size: 8px;
            }
          }
        }
      }
      .share-heading{
        text-align: center;
        color:#D3D3D3;
        font-size: 12px;
      }
      .modal-title{
        font-size: 16px;
        color: #4a4a4a;
      }
      .share-text{
        background:#ffffff;
        border:1px solid #d2d1d1;
        border-radius:3px;
        margin-top: 10px;
        font-size: 12px;
        display: inline-block;
        height:29px;
        width: 520px;
        border-radius: 5px 0px 0px 5px;
      }

      .share-email{
        background:#ffffff;
        border:1px solid #d2d1d1;
        border-radius:0px;
        margin-top: 10px;
        font-size: 12px;
        display: inline-block;
        height:29px;
        width: 520px;
      }

      .share-button{
        padding: 5px 20px;
        background: #f1f1f1;
        line-height: 17px!important;
        border: 1px solid #d3d3d3;
        border-radius: 0px 5px 5px 0px;
        cursor: pointer;
      }

      .email-chips{
        span{
          margin-left:5px;
          font-size: 14px;
        }

        input[type=text]{
          height: 20px;
          font-size: 12px;
          textarea{
            height: 20px;
            font-size: 12px;
          }
          body{
            textarea{
             height: 20px;
             font-size: 12px;
           }
         }
        }
      }

      .done-button{
        padding: 5px 20px;
        background: #017bff;
        line-height: 17px!important;
        margin-top: 20px;
        color: #ffffff;
        border: 1px solid #017bff;
        cursor: pointer;
      }
      .switch-container{
        margin-top: 25px;
        label{
          position: relative;
          left: 30px;
          top: 0px;
        }
        .switch-content{
          position: absolute;
          left: 180px;
          top: -5px;
          width: 125px;
        }
      }
      .copy-link-success{
        background: #fff3cd;
        color: #856404!important;
        text-align: center;
        padding: 10px;
        margin-left: 240px;
        top: 10px;
        position: relative;
      }


      .user-share-modal{
        width: 45px;
        margin-top: 57px;
        padding: 5px 0px 8px 0px;
        position: relative;
        border:1px solid #cccccc;
        border-radius: 5px;
        .dropdown-menu{
          top: 7px !important;
          padding: 0;
          button{
            color: #000;
            font-family:$ibmplexRegular;
            font-size: 14px;
            cursor: pointer;
            border-bottom: 1px solid #dfe3e6;
            padding: 6px 12px;
            a{
              color: #000;
            }
          }
          .dropdown-item{
            &:hover{
              background: #ccc;
            }
          }
        }
        .show>.btn-secondary.dropdown-toggle{
          background: transparent;
          border: none;
        }
        .btn{
          padding: 0;
        }
        .dropdown-toggle{
          &:after{
            position: absolute;
            top: 10px;
            right: 2px;
            color: #2f8cff;
          }
        }
        .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
          box-shadow: none;
        }
        btn-secondary.focus,.btn-secondary:focus{
          border: none;
          box-shadow: none;
        }
        .btn-secondary, .dropdown-toggle{
          background: transparent;
          border: none;
          box-shadow: none;
        }
        .share-blk{
          h4{
            font-size: 12px;
            margin-bottom: 3px;
            margin-right: 5px;
            font-family:$ibmplexRegular;
            text-align: center;
            .fas,.fa{
              color: #7c7878;
              font-size: 14px;
            }
          }
          p{
            font-size: 12px;
            color: #000;
            font-family:$ibmplexRegular;
            text-align: left;
          }
        }
       .dropdown{
         width: 97%;
          >button{
            width: 100%;
            cursor: pointer;
            &:active,&:focus{
              background: transparent;
              background-color: transparent;
            }

          }
       }
      }
    }




  }
  .popover{
    min-width: 250px;
    .close-popover{
      float: right;
      cursor: pointer;
      margin-left: 5px;
      color: #a8a8a8;
    }
    .sub-folder{
      float: right;
      width: 10px;
      padding-top: 5px;
    }
    .popover-header{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:#4a4a4a;
      text-align:left;
    }
    .popover-body
    {
      max-height: 300px;
      overflow-x: scroll;
    }
    ul{
      margin-left: -10px;
      margin-right: -10px;
      li{
        display: block;
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#4a4a4a;
        text-align:left;
        padding: 6px 12px;
        cursor: pointer;
        &:hover{
          background-color: #ededed
        }
        img{
          width: 20px;
          padding-right: 5px;
        }
      }
      li:first-child{
        padding: 0px 5px 0px 0px;
        img{
          width: 40px;
          padding: 0px;
        }
      }
      .active{
        background: #a6a6a6;
        color: #ffffff;
        &:hover{
          background: #a6a6a6;
          color: #ffffff;
        }
      }
    }
    .btn{
      font-family: $ibmplexRegular;
      font-size: 12px;
      float: right;
      margin-bottom: 10px;
      margin-right: 10px;
      margin-top: 10px;
      padding: 5px;
    }
  }
  .validatedFiles{
    .list-inline{
      margin: 0 auto;
    }
    .modal-body{
      height: 350px;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .modal-header{
      padding-top: 0px;
    }
    .select-all{
      display: block;
      margin-left: 15px;
      input[type = 'checkbox']{
        margin-right: 5px;
      }
      font-size: 14px;
    }
    .validfiles-heading{
      margin-left: 15px;
      margin-top: 15px;
      font-size: 12px;
    }
    .improper-files-heading{
      text-align: center;
      img{
        padding: 10px;
        width: 50px;
      }
      h2{
        text-align: center;
        font-size: 18px;
        span{
          color: #7676e4;
        }
      }
    }
    .records-list-wrap{
      .record-list{
        border: none!important;
        word-break: break-all;
        background: #f5f5f5;
        line-height: 30px;
        input[type = "checkbox"]{
          margin-right: 5px;
        }
      }
      .record-list:last-child{
        border: none;
      }
    }
    .beta{
      margin: 0 0 8px 0;
      font-size: 14px;
    }
    .convention-list{
      font-size: 12px;
      color: #bb986b;
    }
    .btn{
      padding: 10px 30px;
      background: none;
    }
    .btn-success{
      color: #2f8cff;
      border-color: #2f8cff;
      box-shadow: 0 4px 4px -4px #2f8cff;
    }
    .btn-warning{
      color: #6bd28f;
      border-color: #6bd28f;
      box-shadow: 0 4px 4px -4px #6bd28f;
      background: #ecfff3;
    }
    .btn-danger{
      padding: 20px 25px;
      color: #f90505;
      border-color: #d6d6d6;
      box-shadow: 0 4px 4px -4px #d3d3d3;
    }
  }
  .bulk-data-modal-width {
    .modal-content{
      margin: 0 auto;
      max-width: 450px !important;
    }
  }



}
