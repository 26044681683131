@import '../../../assets/styles/setting';
.assets-detail-section{
  position: relative;
  .contract-draft-mode{
    position: fixed;
    width: 100%;
    top: 45px;
    z-index: 0;
    background: #fff3cd;
    text-align: center;
    color: #856404;
    padding: 2px 0;
    font-size: 10px;
    font-family: Conv_IBMPlexSans-Regular;
    border: 1px solid #d4bb73;
  }
  .engines-info{
    .aims-primary-button{
      font-size: 12px;
      font-family: $ibmplexLight;
      display: block;
      margin-top: 10px;
      cursor: pointer;
    }
    h5{
      color:#9439E4;
      font-size:12px;
      font-family: $ibmplexSemiBold;
      text-transform: uppercase;
      border-top:1px dotted #d7d7d7;
      padding:10px 0px 15px 0px;
      margin-top:15px;
    }
  }
  .asset-detail-info{
    background-repeat: no-repeat !important;
    &.apu{
      background-size: 300px!important;
      background-position-x: 50px!important;
      background-position-y: 80px!important;
    }
    &.esn{
      background-position: left 250% top 50%  !important;
    }
    span.label-title{
      font-size: 12px;
      font-family: $ibmplexLight;
      color: #54616d;
      display: block;
      margin-bottom: 3px;
    }
    h6{
      font-size: 14px;
      font-family: $ibmplexRegular;
      color: $color_000;
    }
    .back-navigation{
      ul{
        list-style: none;
        li{
          color: #0A56A7;
          font-size: 10px;
          font-family: $ibmplexRegular;
          &.back-icon{
            margin-bottom: 5px;
          }
          img{
            width: 10px;
          }
          &.asset-view-heading{
            color:$color_000;
            font-size: 20px;
          }
        }
      }
      h1{
        font-size: 20px;
        font-family: $ibmplexRegular;
        color: $color_000;
      }
    }
    .aircraft-engine-type{
      margin-bottom: 24px;
      span{
        font-size: 14px;
        color: #9439E4;
        font-family: $ibmplexRegular;
      }
      h1{
        font-size: 28px;
        color: $color_000;
        font-family: $ibmplexLight;
      }
    }
    .specs-info{
      border-top: 1px solid #EEEEEE;
      padding-top: 30px;
      margin-top: 0;
      h4{
        font-size: 16px;
        color: $color_000;
        font-family: $ibmplexRegular;
        margin-bottom: 20px;
      }
    }
  }
  .parts-info{
    margin-bottom: 20px;
    ul{
      li{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .aims-different-product-list{
    background-image:linear-gradient(-90deg, #6538DB 0%, #0A56A8 100%);
    .platform-card{
      background: rgba(255,255,255, 0.1);
      border-radius: 3px;
      -webkit-box-shadow: 0px 15px 34px -10px rgba(0,0,0,0.20);
      -moz-box-shadow: 0px 15px 34px -10px rgba(0,0,0,0.20);
      box-shadow: 0px 15px 34px -10px rgba(0,0,0,0.20);
      padding: 15px ;
      position: relative;
      height: 180px;
      margin-bottom: 30px;
      .progress-meter{
        margin-bottom: 16px;
        h4{
          color: $color_fff;
          font-family: $ibmplexSemiBold;
          font-weight: 600;
          font-size: 17px;
          margin-bottom: 8px;
          img{
            width: 18px;
            margin-right: 3px;
          }
        }
        .platform-progress{
          height: 3px;
          width: 100%;
          background: rgba(255,255,255,0.18);
          position: relative;
          .progress-bar{
            background-image:linear-gradient(to left, #52E9AD, #2C92FF);
          }
          .progress{
            height: 3px;
          }
          span{
            background-image:linear-gradient(-180deg, #52E9AD 0%, #2C92FF 100%);
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
          }
        }
      }
      .ptl-info{
        margin-bottom: 0px;
        span{
          color: $color_fff;
          font-family: $ibmplexRegular;
          font-size: 12px;
        }
        h6{
          color: #FFF03B;
          font-family: $ibmplexRegular;
          &.spacing-top{
            margin-top:8px;
          }
          img{
            width: 30px;
          }
        }
      }
      .plt-last-activity{
        background: rgba(209, 209, 209, 0.1);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 8px 15px;
        ul{
          list-style: none;
          li{
            float: left;
            color: $color_fff;
            font-size: 12px;
            font-family: $ibmplexRegular;
            img{
              margin-right:6px;
              position: relative;
              top: -1px;
            }
            &:first-child{
              padding-right: 6px;
            }
            &:last-child{
              color: #FFF03B;
            }
          }
        }
      }
    }
    .colapsable-wrapper{
          .card{
            border:none;
            background: rgba(255, 255, 255, 0);
          }
          .card-body{
            background: rgba(255,255,255, 0.1);
            padding:10px;
            .row{
              margin-left: -5px;
              margin-right: -5px;
              padding-bottom: 8px;
              font-size: 12px;
              font-family: $ibmplexRegular;
            }
          }
          .form-collapse-title{
              font-family:$ibmplexSemiBold;
              font-size:16px;
              color:#000000;
              margin-bottom:0;
              padding: 15px;
              border-radius: 3px;
              cursor:pointer;
              transition: all 0.5s ease;
              background: rgba(255,255,255, 0.1);
              &:hover{
                color:#2f8cff;
              }
              img{
                float: right;
                width: 18px;
              }
        }

    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 768px) {
  header{
    display: none !important;
  }
  .assets-detail-section{
    .asset-detail-info{
      padding-top:15px;
      .back-navigation{
        margin-bottom: 40px;
      }
    }
    .aims-different-product-list{
      margin: 0 -15px;
      padding: 15px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .assets-detail-section{

  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .assets-detail-section{

  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .assets-detail-section{
    padding-left: 15px;
    margin-right:-15px;
    .aims-different-product-list{
      padding: 30px 40px 20px 40px;
      width: calc(100% - 400px);
      min-height: 100vh;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .assets-detail-section{
    padding-left: 15px;
    margin-right:-15px;
    .asset-detail-info{
      width: 400px;
      height: auto;
      padding: 20px 24px 60px 55px;
      background-position-x:-90%;
      .back-navigation{
        margin-bottom: 168px;
      }
    }
    .aims-different-product-list{
      padding: 30px 40px 20px 40px;
      width: calc(100% - 400px);
      min-height: 100vh;
    }
  }
}
