@import '../../../../assets/styles/setting';

.config-card-info{
  p{
    text-align: left;
    span{
      font-family: $ibmplexRegular!important;
      a{
        float: none;
        font-weight: 500;
        font-family: $ibmplexSemiBold!important;
      }
    }
  }
}
.ml-modal-upload{
  padding: 80px 0 215px 0 ;
  text-align: center;

  h3{
    font-family:$ibmplexLight;
    font-size:20px;
    color:#2f8cff;
    text-align:center;
    margin-bottom:8px;
  }
  p{
    font-family:$ibmplexLight;
    font-size:14px;
    color:#8b8d91;
    text-align:center;
    line-height:20px;
    margin-bottom:30px;
  }
  img{
    margin:0 auto 38px;
    display: block;
  }
  h5{
    font-family:$ibmplexLight;
    font-size:14px;
    color: $color_000;
    text-align:center;
    margin-bottom:10px;
    line-height: 24px;
  }
  .upload-file{
    position: relative;
    cursor: pointer;
    color:$color_fff;
    padding:4px 17px;
    font-family:$ibmplexLight;
    font-size:12px;
    color:$color_fff !important;
    text-align:center;
    padding: 6px 26px;
    z-index: 1;
    input[type="file"]{
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      opacity: 0;
      cursor: pointer;

    }
  }


}

.mluploaded-file{
  // margin: 0px 21px;
  margin-left: 60px;
  h4{
    font-family:$ibmplexLight;
    font-size:16px;
    color:#bd10e0;
    margin: 0 6px 15px;
  }

  .notice-info{
    font-size: 11px;
    margin-left: 25px;
    margin-bottom: 20px;
    margin-top: -30px;
    background: #fff1b6;
    padding: 2px 5px;
    color: #a78701;
    border: 1px solid #a78701;
    border-radius: 4px;
    position: relative;
    padding: 5px;
    padding-left: 27px;
    span{
      font-family: $ibmplexSemiBold;
    }
    .caution-img{
      position: absolute;
      left: 6px;
      height: 14px;
      top: 6px;
    }
    .h6{
      color:#a78701;
      font-size: 11px;
    }
    a{
      float: right;
    }
  }


  &.data-log-list{
    .list-row{
      margin-bottom: 20px;
      display: block;
      .file-type-status{
        display: inline-block;
        color: #ffffff;
        background: #a1a1a1;
        padding: 3px 6px;
        border-radius: 2px;
        text-transform: uppercase;
        font-size: 10px;
        font-family: $ibmplexRegular;
      }
    }
    .upload-by{
      img{
        border-radius:50%;
        width: 35px;
        height: 35px;
        // object-fit: contain;
      }
    }
  }
  .upload-image-block{
    margin-bottom:30px;

    img{
      width: 45px;
      display: block;
      margin: 0 auto 4px;
      height: 45px;
      border-radius: 50%;
    }

    span{
      display: block;
      text-align: center;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#8c9ba5;
      b{
          color:#2881FF;
          font-family:$ibmplexSemiBold;

      }
    }
  }
  .confirm-blk{
    padding: 15px 0 20px;
    text-align:center;
    .cstm-progress-bar{
      height: 10px;
      background: #73CD1F;
      display: inline-block;
      position: absolute;
      left:0;
      bottom:0;
    }
    button{
      padding: 7px 45px;
      cursor: pointer;
      &:disabled{
        opacity: 0.6;
      }
    }
    .cancel-modal{
      font-family:$ibmplexRegular;
      font-size: 12px;
      padding: 0 20px;
      cursor: pointer;
      display: inline-block;
      margin: 18px 0;
    }
  }
  .upload-row{
    background:#ffffff;
    border:1px solid #dfe3e6;
    margin: 0 6px;
    padding: 6px 0;
    img{
      margin-right:10px;
      &.delete-icon{
        width: 12px;
        position: absolute;
        right: 4px;
        top: 6px;
        cursor: pointer;
      }
    }
    span{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#8c9ba5;
    }
    .dropdown-toggle{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:$color_000;
      background:#f7f7f7;
      border-radius:3px;
      &:active, &:focus, &:hover{
        font-size:12px;
        color:$color_000 !important;
        box-shadow: 0 0 0 !important;
        background:#f7f7f7 !important;
        color: #000 !important;
        border-radius:3px;
      }
    }
    .dropdown-menu{
      background:#fbfbfb;
      box-shadow:0 2px 4px 0 rgba(0,0,0,0.18);
      border-radius:3px;
      padding:0 !important;
      button{
        padding:4px;
        border-bottom:1px solid #e4e4e4;
        font-family:$ibmplexRegular;
        font-size:12px;
      }
    }
    .change-file{
      position: relative;

      width: 20px;
      height:20px;
      cursor: pointer;
      overflow: hidden;
      img{
        cursor: pointer;
        width: 10px;
        margin-right: 17px;
        display: inline-block;
        position: relative;
        bottom: 2px;
      }
      input[type="file"]{
        cursor: pointer !important;
        position: absolute;
        left:0;
        right:0;
        bottom: 0;
        top: 0;
        width:100%;
        opacity: 0;
      }
    }
  }
  .awiting-for-approval{
    border: 1px solid #faebcc;
    margin: 0 6px;
    background:#fcf8e3;
    p{
      padding: 6px 0;
      font-family:$ibmplexRegular;
      color: #8a6d3b;
      font-size:12px;
    }
  }
  .pending-status-data{
    border: 1px solid #86b4d2;
    margin: 0 6px;
    background:#e7f1ff;
    p{
      padding: 6px 0;
      font-family:$ibmplexRegular;
      color:#000000;
      b{
        color:#2f8cff;
        font-family: $ibmplexSemiBold;
      }
      a{
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#2f8cff;
        border-bottom:1px solid #2f8cff;
        margin-left:6px;
        display: inline-block;
      }
    }
  }

  .drag-drop-block{
    margin: 0 7px;
    margin-bottom: 40px;
    background:#e9e9e9;
    border:1px dashed #979797;
    border-radius:6px;
    text-align: center;
    padding:30px 0;
    p{
     color: #8c9ba5;
      font-family: $ibmplexRegular;
     a{
      position: relative;
      padding: 6px 20px;
      margin-left: 12px;
      color: #fff;
      cursor: pointer;
      &:hover{
        color: #fff;
      }
      input[type="file"]{
        opacity:0;
        position: absolute;
        left:0;
        right:0;
        width:100%;
      }
    }
    }

  }
}



/***** log view scss blcok*******/
.lg-view-block{
  position: relative;
  .encountered-btn{
    display: block;
    font-size: 12px;
    text-align: right;
    margin-bottom: 8px;

    position: absolute;
    right: 0;
    top: -21px;
    a{
      color:#2f8cff;
      display: inline-block;
      margin-left: 5px;
      font-family: $ibmplexSemiBold;
      transition: all 0.2s ease;
      border-bottom:1px solid #f0f2f3;

      &:hover{
        color:#2f8cff;
        border-bottom:1px solid #2f8cff;
      }
    }
  }
  .reserve-acnt{
    font-size: 18px;
    margin-bottom: 16px;
    margin-left: 60px;
  }
  .data-log-view-block{
    margin-bottom:20px;
    margin-left: 60px;
    background: #fff;

    h5{
      font-family:$ibmplexRegular;
      font-size:16px;
      color:#9439e4;
      padding:15px;
    }
    .log-detail-blk{
      border-bottom:1px solid #eae9e9;
      >div{
        padding: 15px;
      }
      h6{
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#8c9ba5;
        img{
          position:relative;
          top:-2px;
        }
      }
      p{
        font-size: 15px;
        font-family:$ibmplexRegular;
      }


      &.lg-status-action{
        border-bottom:1px solid #e1e1e1;
        background: #fafafa;
        .custom-width{
          width:70%;
          .checkbox-blk{
            margin-right:12px;
            float:left;
            cursor: pointer;
            input[type="checkbox"]{
              cursor: pointer;
            }
          }
        }
        .action-block{
          text-align: right;
          width: 29%;
          .applied-btn{
            font-size: 12px;
            display: inline-block;
            // margin-right: 20px;
          }

          a{
            font-family:$ibmplexRegular;
            display: inline-block;
            margin:0 0 0 25px;
            cursor: pointer;
            &.ignore-log{
              color:#F4D020;

            }
            &.reject-log {
              color:#ff5e5e;
            }
            &.approve-log{
              color:#73CD1F;
            }
          }
        }
      }

    }

  }
  .approve-data-point{
    position: fixed;
    right: 0px;
    padding: 8px 23px;
    background: #fff;
    box-shadow: 0 0px 4px 4px rgba(0,0,0,0.1);
    span{
      font-family: $ibmplexRegular;
      color: #2f8cff;
      font-size: 14px;
      &.count{
        display: block;
        font-size: 10px;
        text-align: center;
      }
    }
    button{
      cursor: pointer;
      padding: 5px 20px;
      margin-bottom:4px;
      width:145px;
    }


    .back-lg-block{
      .select-all-block{
        margin-left:6px;
        background: $color_fff;
        display: inline-block;
        color:#b7b7b7;
        label{
          cursor: pointer;
          font-family: $ibmplexRegular;
          font-size: 12px;
          input[type="checkbox"]{
            margin-right: 5px;
          }
        }
      }

      .back-nav{
        font-family:$ibmplexRegular;
        font-size:14px;
        color:#2f8cff;
        img{
          width: 18px;
          margin-right: 6px;
        }

      }
      .right-blk{
        text-align: right;
        justify-content: flex-end;
        ul{
          justify-content: flex-end;
          li{
            margin-left:20px;
            a{
              font-family:$ibmplexRegular;
              font-size:12px;
              display:inline-block;
              &.downlaod{
                color:#2f8cff;
                img{
                  margin-right: 6px;
                  display: inline-block;
                }
              }
              &.ignore-log{
                color:#F4D020;

              }
              &.reject-log{
                color:#ff5e5e;
              }
              &.approve-btn{
                color:#73CD1F;

              }
            }
          }
        }


      }
    }
  }
}


@media (max-width: 575.98px) {
  .lg-view-block{
    .approve-data-point{
      left: 0;
    }
  }


}


@media (min-width: 992px) and (max-width: 1199.98px) {
  .lg-view-block{
    margin: 144px 8px 15px 224px;
    .approve-data-point{
      left: 240px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .lg-view-block{
    margin: 170px 8px 15px 224px;
    .approve-data-point{
      left: 240px;
    }
  }
}
