@import '../../../../assets/styles/setting';
#addRentalUtilForm{
  .modal-content{
    width: 500px;
  }
  .apps-container{
    margin-left: 10px;
  }
  .transfer-modal-body{
    margin-bottom: 0px;
  }
}
.invoice-table-style{
  margin-top:30px;
  thead{
    th{
      background:#264061;
      color:#ffffff !important;
    }
  }
}

.generate-finance-heading{
  left: 5px;
  top: 50px;
  padding-bottom: 15px!important;
}
.generate-finance-heading-short{
  left: 5px!important;
  top: 50px!important;
  padding-bottom: 40px!important;
}
.fixed-select{
  position: fixed;
  width: 20%;
  top: 85px;
}
.contr-table-blk{
  border: 1px solid #c6cddd;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  border-radius: 3px 3px 0 0;
  margin-bottom: 40px;
  position: relative;
  .action-link{
    font-size: 12px;
    background: #2f8cff!important;
    color: #fff!important;
    border: 1px solid #2670ca;
    border-radius: 3px;
    padding: 5px;
    float: right;
  }
  .detail-text-content{
    font-size: 12px;
    margin: 10px;
    border: 1px solid #353a4a;
    border-radius: 3px;
    padding: 10px;
    background: #dfe4e9;
  }
  .no-records-found{
    margin:0;
  }
  .cycle-remaining-warning{
    td{
      color:rgb(255, 0, 0) !important;
    }
  }
  .table-block{
    h6{
      padding: 5px;
    }
  }
  h5{
    font-family: Conv_IBMPlexSans-Regular;
    font-size: 16px;
    color: #194886;
    padding: 15px;
    border-bottom: 1px solid #c6cddd;
    background: #ffffff;
   .edit-table-block{
     float:right;
     }
    span{
      float: right;
      cursor: pointer;
      img{
        width: 18px;
      }
      &.add{
        font-size: 12px;
        margin-left:5px;
      }
      &.in-audit-history{
        font-size: 12px;
        margin-left: 20px;
        position: relative;
        top: 2px;
        cursor: pointer;
      }
    }
  }
  .contr-table{
    width: 100%;
    background: #fff;
    thead{
      background: rgba(255,255,255,0.07);
      background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
      background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
      th{
        font-family:$ibmplexRegular;
        font-size:12px;
        color: #8c9ba5;
        padding: 15px;
      }
    }
    tbody{
      tr{
        cursor: pointer;
        transition:all 0.4s ease;
        &:nth-child(odd){
          background: #EBEFF6;
        }
        &:nth-child(even){
          background: #f5f7fa;
        }
        &:hover{
          background: #D0D9E8;
        }
        td{
          padding: 15px;
          span{
            font-family:$ibmplexRegular;
            font-size:14px;
            color: #000;
          }
        }
      }
    }
  }
  .tableSubmit-block{
    background: #fff;
    padding: 15px;
    text-align: right;
    a{
      cursor: pointer;
      font-family:$ibmplexRegular;
      font-size: 12px;
      color: #2f8cff;
      display: inline-block;
      margin: 0 30px;

    }
  }


  .config-side-bar{
    .sidebar-form{

      .seatconfig-type-wrapper{
        border-bottom:1px solid #ddd;
        margin-bottom: 15px;
        padding-bottom: 10px;
        &.border-none{
          border-bottom:0;
          margin-bottom:0;
        }
      }
      .delete-custom{
        bottom: 39px;
        position: absolute;
        right: 19px;
        z-index: 1;
      }
      .seat-add-section{
        height: 70px;
        a{
          display: inline-block;
          margin: 0 10px 0 0;
          i{
            color: #7f8fa4;
            font-size: 20px;
          }
        }
      }
      .add-section{
        a{
          background-color: #eee;
          border-radius: 27px;
          padding: 10px 30px;
          font-size: 14px;
          color: #000;
        }
      }
    }
   }
}
.working-groups-blk{
  .working-grp-title{
    p{
      display: inline;
      cursor: pointer;
    }
    ul{
      float: right;
      li{
        padding: 0 4px;
        img{
          width: 16px;
          cursor: pointer;
        }
        .wg-audit-history{
          color: #2f8cff;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

  }
  .card-body{
    padding: 0;
  }
}

.mr-rate-wrapper{

 .invoice-heading{
  color:#333;
  margin-top: 10px;
  font-family: $ibmplexSemiBold;
  position:relative;
  margin-bottom:15px;
  padding:0 15px;
  .back-link{
    display: inline-block;
    img{
      float: left;
      margin-top: 12px;
    }
    .inv-back-info{
      margin-left: 24px;
    }
    span{
      font-family: $ibmplexRegular;
      color: #000;
      font-size: 12px;
    }
    h6{
      color: #000;
      font-size: 20px;
    }

    .back-link-single{
      display: inline-block;
      color: #000;
    }

  }
  .edit-icon{
    float:right;
    cursor: pointer;
    font-size:14px;
    padding: 0 20px;
     &.edit-icon-bar{
      padding: 0 10px;
     }
    img{
      margin-right:2px;
      width: 12px;
      position: relative;
      top: -2px;
    }
  }

}
 .add-edit-invoice-form{
  background: rgb(255, 255, 255);
  margin-right: -15px;
  margin-left: 45px;
  padding: 20px 0 0;
  .view-block{
      padding-bottom:20px;
  }
   .currency-break{
    font-family:$ibmplexSemiBold;
      font-size:16px;
      color:#bd10e0;
      margin-top: 20px;
   }
   .form-group{
     margin-bottom:30px;
     .react-datepicker-wrapper{
       width: 100%;
     }
     .react-datepicker__input-container{
       width: 100%;
     }
     input[type="text"], textarea{
       border-bottom: 1px solid #8e8e8e;
       color: #000;
       display: block;
       width: 100%;

     }

     textarea{
       resize: none;
     }
   }
   .bank-detail-block{
     padding:15px;
     background: #F5F7FA;
     margin:0 15px 15px;

     h5{
       color:$color_b9b;
       margin-bottom:10px;
     }
     input{
       background: transparent;
     }
   }
   .company-form-block{
      margin-bottom:8px;
    .inner-wrap{
      margin: 0 0 10px;
      input{
        background:transparent;
      }
      label{
        margin-bottom:0;
      }
      .form-group{
        margin-bottom: 10px !important;
      }
      .heading-block{
        padding: 15px;
        color:$color_9e4;

      }
    }
   }
  .charge-description-block{
    margin:0 15px 15px;
    .contr-table-blk{
      margin-bottom:12px;
      position: relative;
    }

    table{
      width:100%;
      margin-bottom:15px;
      th{
        color: #9439e4;
        padding:15px;
        font-size: 13px;
        &:last-child{
          text-align: right;
        }
      }
      td{
        padding:15px;
       color: $color_000;
       &.invoice-type-block{
        width: 150px;
       }
       &.invoice-description-block{
        width:200px;
        textarea{
          height: 70px;
        }
       }
       &.invoice-penalty-description-block{
        width:400px;
       }
       &.invoice-date-block{
        width:200px;
       }
       &.invoice-overdue-day{
        width:70px;
       }
       &.invoice-usd-day{
        width:80px;
       }

        input, textarea{
         color:$color_000;
         border-bottom: 1px solid #8e8e8e;
          width:100%;
          background:transparent;
          &:disabled{
            border-bottom:0 !important;
          }
        }
        .Select{
          .Select-control{
           background: transparent !important;

          }
        }

       &:last-child{
          text-align:right;
       }
      }
      textarea{
       resize: none;
      }
    }

    .invoice-add-item{
      cursor: pointer;
      img{
        position: relative;
        top: -1px;
        margin-right:6px;
      }
      a{
        font-size: 12px;
        color: #fff;
        padding: 4px 20px;
        border-radius: 18px;
      }
     }
    .sub-total-block{
      h5{
        color:$color_b9b;
      }
      h4{
        color:$color_000;
      }
   }
   }


   .sub-total-block{
     text-align: right;
     padding:0 0 5px;
     h4{
      font-family: $ibmplexRegular;
     }
     h5{
      font-family: $ibmplexRegular;
      margin-bottom:10px;
     }
   }
   .submit-block{
    background: #f5f7fa;
    padding:15px ;
    input[type="submit"]{
     padding:2px 22px;
     cursor: pointer;
     font-family: $ibmplexRegular;
     font-size: 12px;
     color: $color_fff;
     padding: 6px 25px;
     margin-right: 30px;
    }
    a{
      cursor: pointer;
      font-family: $ibmplexRegular;
      font-size: 12px;
      color: #2f8cff;
    }
  }
 }
 .contract-invoice-list{
   .add-filter-type {
     border-top:1px solid #dfe3e6;
     border-left:1px solid #dfe3e6;
     border-right:1px solid #dfe3e6;
   }
   .generate-invoice{
     margin-bottom: 20px;
     justify-content: flex-start;
     button{
       float: right;
       clear: both;
     }
   }
   .asset-list{
     h6{
       font-size: 14px;
     }
     .pretty-number-override-css {
      font-family: $ibmplexRegular;
      font-size: 14px;
    }
   }

 }
 .mr-rate-inner-wrapper{
  padding: 0px 15px;
  margin-left: 60px;
  .rental-block{
   padding-bottom: 30px;
   h3{
    font-family:$ibmplexRegular;
    font-size:20px;
    color:$color_000;
    margin-bottom:10px;
    .add-new{
      float: right;
    }
    .auditlog-btn{
      margin-right:15px;
      float: right;

    }
    .pretty-number-override-css{
      h6{
      font-size: 14px;
      }

    }
   }
   p{
    font-family:$ibmplexRegular;
    font-size:14px;
    color:#8b8d91;

   }
  }
  .card-block{
    .row{
      >div{
        margin-bottom:20px;
      }
    }
    h6{
      font-family:$ibmplexRegular;
      font-size: 12px;
      color: #a0a0a0;

    }
    span{
      font-family:$ibmplexRegular;
      font-size: 14px;
      color: #000;
      line-height: 20px;
    }
  }
 }
 .invoice-cards{
    margin: 0 0 30px 0;
    .padding-right-0{
      margin-bottom: 20px;
    }
  .invoice-inner-block{
    background-image: linear-gradient(0deg, #6542c1 0%, #6189ff 100%);
    border-radius: 1px;
    padding:15px;
    height:100%;

    h5{
      font-family:$ibmplexSemiBold;
      font-size:16px;
      color:#bfe6ff;
      line-height:22px;
      margin-bottom:11px;
      &.invoice-space-block{
        margin-bottom: 30px;
      }
    }
    h6{
      opacity:0.7;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:$color_fff;
      margin-bottom:5px;
    }
    p{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:$color_fff;
      line-height:22px;
      margin-bottom:15px;
      &.space-block{
        margin-bottom: 6px;
      }
      .as-of-date{
        color: #ffffff !important;
      }
    }

    .progress{
      height: 3px !important;
      background: #ff0000;
      .progress-bar{
         background: #2fc92f;

      }
    }
    .green{
      .progress{
        background: #2fc92f;

      }
    }
    }
 }
 .create-invoice{
   a{
     cursor:pointer;
    font-family:$ibmplexRegular;
    font-size:12px;
    color:$color_fff;
    padding: 6px 30px;
    float: right;
    &:hover{
      color:$color_fff;

    }

   }
   .btn-group{
     float: right;
     button{
       background: transparent;
       border:0;
       &.active, &:focus, &:focus-within{
         background: transparent;
         box-shadow: 0 0 0;
       }
       span{
         color:#2f8cff;
       }
     }
     .dropdown-menu{
       padding:0;
       img{
        margin-right: 4px;
        position: relative;
        top: -1px;
       }
       button{
         font-size: 14px;
         padding:6px 12px;
       }
     }
   }
   .invoice-tab{
    margin-left:15px;
    border-bottom: 1px solid #c6cddd;
    margin-bottom: 15px;
    li{
     font-family:$ibmplexRegular;
     font-size:12px;
     color:#8c9ba5;
     text-align:center;
     margin:0 13px;
     &.active{
       color:#1c97fb;
       border-bottom:1px solid #1c97fb;
     }
    }
  }
 }


}

.data-invoice-block{
  position: relative;
  background-image:linear-gradient(90deg, #6542c1 0%, #6189ff 100%);
  border:1px solid #c6cddd;
  padding:15px 15px 0;
  .edit-card-icon{
    cursor: pointer;
    position: absolute;
    right: 15px;
  }
  h6{
    font-family:$ibmplexRegular;
    font-size:12px;
    color:#f0a4ff;
  }
  span{
    font-family:$ibmplexRegular;
  font-size:14px;
  color:$color_fff !important;
  line-height:20px;
  }
  p{
    font-family:$ibmplexRegular;
  font-size:16px;
  color:$color_fff;
  margin-bottom:15px;
  }
  .invoice-inner-warp{
    margin-bottom:30px;
  }
.invoice-last-block{
  background: rgba(255,255,255,0.3);
  padding: 15px 0;
  .invoice-inner-warp{
    margin-bottom:15px;
    &:nth-child(3n+1):nth-last-child(-n+3),
      &:nth-child(3n+1):nth-last-child(-n+3) ~ .invoice-inner-warp {
        margin-bottom:0;
      }
    p{
      font-size:14px;
      margin-bottom:0;

    }

  }
}
}

/***** table view scss *******/
 .table-view{
   &.table-border{
    background:#f5f7fa;
    border:1px solid #c6cddd;
    border-radius:0 0 3px 3px;
   }
   &.space-bottom{
     margin-bottom:15px;
   }
  h6{
   font-family:$ibmplexRegular;
   font-size:12px;
   color:#8c9ba5;
   padding:15px 0;

   background-image:linear-gradient(0deg, invalid gradient);
    border-radius:0 0 3px 3px;

  }
  span{
   font-family:$ibmplexRegular;
   font-size:14px;
   columns: $color_000;
  }

  .table-view-header{
    background: rgba(255,255,255,0.07);
    background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
    margin:0;
  }

  .table-view-body{
    padding:8px 0;
    margin:0;
    &:nth-child(odd){
      background:#ebeff6;

    }
    &:nth-child(even){
      background:#f5f7fa;
    }
  }
 }

 .qualified-table{
  background:#f5f7fa;
  border-radius:0 0 3px 3px;
  width:100%;
  thead{
    tr{
      background: rgba(255,255,255,0.07);
      background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
      background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
      margin:0;
      th{
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#8c9ba5;
        padding:12px 10px;
      }
    }

   }
   tbody{
     tr{
       cursor: pointer;
      &:nth-child(even){
        background:#f5f7fa;
      }
      &:nth-child(odd){
        background:#ebeff6;
      }
      td{
        padding:12px 10px;
        font-family:$ibmplexRegular;
        font-size:14px;
        color:$color_000;
        line-height:20px;

      }
     }

   }
 }

/*** no data scss***/
.no-data {
  min-height:150px;
  >div{
    text-align: center;
  }
  span{
    font-family:$ibmplexRegular;
    font-size:14px;
    color:#afafaf;


  }
}



 /***** collapse scss ******/
 .collpase-wrapper{
   margin-bottom:20px;
  background:#ffffff;
  border:1px solid #c6cddd;
  box-shadow:0 2px 4px 0 rgba(0,0,0,0.10);
  border-radius:3px 3px 0 0;
  h5{
   font-family:$ibmplexRegular;
   font-size:16px;
   color:#194886;
    padding: 15px;
    border-bottom:1px solid #c6cddd;
    .no_items{
      float:right;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:$color_000;
      img{

        margin-left: 15px;
        &.edit-icon{
          // margin-right:15px;
        }
      }
    }
  }

  .card{
   border:0;
   .card-body{
    border:0;
    padding:0;
   }
  }
 }
// collpase middle heading
 .engine-heading{
  font-family:$ibmplexLight;
    font-size:20px;
    color:$color_000;
    padding:75px 0 20px;
}


/*** detail warppwe scss***/
.detail-wrapper{
  border-radius:3px;
  margin-bottom:15px;

  .detail-header{
    padding:12px;
    background-image:linear-gradient(90deg, #6542c1 0%, #6189ff 100%);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    img{
      float: right;
    }
    h5{
      font-family:$ibmplexRegular;
      font-size:16px;
      color:$color_fff;
      margin-bottom:15px;
    }
    h6{
      font-family:$ibmplexRegular;
      color:#f0a4ff;
      text-align:left;
    }
    span{
      font-family:$ibmplexRegular;
      font-size:14px;
      color:$color_fff;
      line-height:20px;
    }
  }
}


// contract file upload scss
.docs-card{
  h5{
    font-family: $ibmplexRegular;
    font-size: 16px;
    color: #9439e4;
    padding-bottom: 15px;
  }
  .attach-file-block{
    cursor: pointer;
    margin-right:10px;
   background:#ffffff;
   border:1px dashed #d4d4d4;
   border-radius:3px;
   width: 124px;
   position: relative;
   overflow: hidden;
   span{
    font-family:$ibmplexRegular;
    font-size:12px;
    color:#8c9ba5;
    display: block;
    text-align: center;
    padding:7px;
   }
   input[type="file"]{
    cursor: pointer;
     opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
   }
  }
  .edit-attached-file{
    background:#f8f8f8;
    border:1px solid #d4d4d4;
    border-radius:3px;
    padding:4px 5px 5px;
    img{
      margin-right: 5px;
      &.delete-icon, &.edit-icon{
        cursor: pointer;

      }
    }
    span{
      margin-right: 5px;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#4a4a4a;
    }
  }
}

// working  directory edit sidebar scss
.working-group-edit{
  background:$color_fff;
  box-shadow:0 2px 4px 0 rgba(0,0,0,0.50);
  width:478px;
  position: fixed;
  right:0;
}

// utilization scss

.contract-container{
  background: $color_fff;
  margin-left:15px;

  .highter {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FB5353;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(251,83,83,0.5);
    animation: pulse 2s infinite;
   }

  h5{
    font-family:$ibmplexRegular;
    font-size:16px;
    color:#bd10e0;
    &.uti-heading{
      padding: 0;
      color:#bd10e0;

    }
    &.main-reserve-fund-status{
      color: #bd10e0;
      font-size: 20px;
       margin-bottom: 15px;
    }
  }
  .util-graph-heading{
    padding: 5px 20px 15px;
    color:#9439e4;
  }


  .utilization-trend-graph{
    margin-bottom: 15px;
    background:$color_fff;
    border:1px solid #c6cddd;
      .utilisation-graph{
        height: 200px;
        background: rgb(255, 255, 255);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

  }
  .lg-checkbox{
    li{
      margin-right:25px;
      input[type="radio"]{
        margin-right: 6px;
      }
      span{
        font-family: $ibmplexRegular;
        font-size: 12px;
      }
    }

  }
  .edit-view-tech-spec{
    margin-right: 15px;
    &.mr-rental-tab{
      margin:0;
    }
  }
}
.utilization-table-block{
  background: $color_fff;
  margin-bottom:15px;

  h5{
    font-family:$ibmplexRegular;
    font-size:16px;
    color:#f0a4ff;
    label{
      font-family:$ibmplexRegular;
      font-size:12px !important;
      color:#f0a4ff;
      float:right;
      position: relative;
      top: -9px;
    }
    .Select{
      width: 110px;
      margin-left:10px;
      .Select-control{
        background: transparent;
        .Select-placeholder{
          color:$color_fff;
        }
      }
    }
  }
  table{
    border: 1px solid #c6cddd;
    background-image: linear-gradient(-90deg, #6542c1 0%, #6189ff 100%);
    width:100%;
    th{
      padding:15px;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#f0a4ff;
    }
    tbody{
      tr{
        font-family:$ibmplexRegular;
        font-size:14px;
        color:$color_fff;
        line-height:20px;
        &:nth-child(odd){
          background:rgba(255,255,255,0.07);

        }
        &:nth-child(even){
          background:rgba(255,255,255,0.04);

        }
        td{
          padding:15px;
          span{
            font-family:$ibmplexRegular;
            font-size:14px;
            color:$color_fff;
            line-height:20px;
          }

        }
      }
    }
  }


}

.utilization-history{
  // margin-left:15px;
  padding:0;
  .asset-list{
    border-bottom:none;
  }
}
.add-edit-utils{
  padding: 5px 20px;
  h5{
    margin: 18px 0;
    a{
      display:inline-block;
      margin-bottom:12px;
      img{
        margin-right: 10px;
        display: inline-block;
      }
      color: #000;
      font-size: 18px;
    }
    .label{
      margin-bottom:0 !important;
    }
    .util-info-block{
      background-color: #FFFBD8;
      border: 1px solid #FFEBD8;
      border-radius: 3px;
      .info-img-block{
        padding: 15px;
        border-right: 1px solid #FFEBD8;
        img{
          width: 21px;
          margin: 0 auto 8px;
          display: block;
        }
        span{
            color: #DAAF36;
            font-family: $ibmplexRegular;
            font-size: 12px;
            display: block;
            text-align: center;
        }
      }
      .bullet-guid{
        list-style-type: none;
        margin-left: 30px;
        img{
          margin-right:6px;
          display:inline-block;
        }
        .note-yellow{
          display:inline-block;
          font-size:12px;
          color:#000;
          padding-bottom:12px;

          b{
            font-family: $ibmplexSemiBold;
          }
        }
        .note-dark-yellow{
          display:inline-block;
          font-size:12px;
          color:#000;

          b{
            font-family: $ibmplexSemiBold;
          }
        }
      }
      }
  }
  .Select{
    width: 150px;
  }



  .select-util-block{
    border: 1px solid #dfe3e6;
    padding: 12px 7px 14px;
    border-bottom:0;
    background: #fff;
  }
  table{
    width: 100%;
    thead{
      border-bottom: 1px solid #eaeaea;

      th{
        background: #f0f2f3;
        padding: 16px 10px;
        font-family:$ibmplexSemiBold;
        font-size:12px;
        color: #8c9ba5;
      }
    }
    tr{

      td{
        font-size: 14px;
        font-family:$ibmplexRegular;
        padding: 10px;
        label{
          margin-bottom: 0;
        }
        input[type="text"]{
          border-bottom: 1px solid #8e8e8e;
          position: relative;
          top:16px;
        }
        textarea{
          border-bottom: 1px solid #8e8e8e;
          resize:none;
          width:100%;
          height: 65px;
        }
      }
    }
  }
  .addutil{
    margin: 15px 0;
    padding: 4px 45px;
    font-size: 15px;
    font-family: $ibmplexRegular;
  }
}

.invoice-status-modal{
  .modal-body{
    padding: 40px;
    height:500px ;
    h5{
      font-size: 16px;
      margin-bottom: 30px;
      font-family: $ibmplexRegular;
    }
    .Select{
      margin-bottom: 30px;
    }
    .react-datepicker__input-container{
      margin-bottom: 20px;
      width: 100%;
      input[type="text"]{
        width: 100%;
        border-bottom: 1px solid #a1a1a1;
      }
    }
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .contract-container{

    h5{
      &.main-reserve-fund-status{
        margin-left:15px;
      }
    }
    .mr-rate-wrapper{

      &.invoice-wrapper{
        padding: 0 15px 0 0;
      }
  }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .contract-container{

    h5{
      &.main-reserve-fund-status{
        margin-left:15px;

      }
    }
    .contract-invoice-list{
      margin-left:15px;

    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contract-container{
    h5{
      &.main-reserve-fund-status{
        margin-left:15px;
      }
    }
    .contract-invoice-list{
      margin-left:15px;

    }
  }
  .mr-rate-spacing{
    margin-left: 230px;
     margin-right: 30px;
     margin-top: 94px;
     .charge-description-block {
       .table-block{
         overflow:auto;
       }
     }
  }
}



// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .contract-container{
    h5{
      &.main-reserve-fund-status{
        margin-left:15px;
      }
    }

  }
  .mr-rate-spacing{
    margin-left: 230px;
     margin-right: 30px;
     margin-top: 94px;
     .charge-description-block {
       .table-block{
         overflow:auto;
       }
     }
  }
}

// Small devices (mobile)

@media (max-width: 575.98px) {
  .generate-finance-heading{
    padding-left:0;
    .hidden-xs{
      display: none;
    }
  }

  .contract-invoice-list{
    .mobile-asset-list{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
     .utilization-history{
      width:300%;
    }
    .asset-list-filter-sort{
      width:300%;
    }
    .asset-list{
      width:300%;

    }
  }
  }


  .cash-flow-wrapper  {
    .mobile-asset-list{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
     .utilization-history{
      width:200%;
    }
    .asset-list-filter-sort{
      width:200%;
    }
    .asset-list{
      width:200%;

    }
  }
  }

  .mr-rate-spacing{
    margin: 0 15px 0 0;

     .charge-description-block {
       .table-block{
         overflow:auto;
       }
     }
  }




}
