@import '../setting';
body{
  .cor-tracker-export-popup{
    ul{
      padding: 40px 0;
      li{
        font-size: 14px;
        font-family: $ibmplexRegular;
        margin-right: 30px !important;
        cursor: pointer;
        color: $black;
        &:hover{
          color: $linkBlue;
        }

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .technical-asset-report{
    width: 80%;
    margin: 80px auto;
    h2{
      font-size: 22px;
      font-family: $ibmplexMedium;
      color: #354052;
      margin-bottom: 10px;
      span{
        float: right;
        font-size: $font14;
        color: #7f8fa4;
        font-family: $ibmplexRegular;
      }
    }
    .asset-form-info{
      background: $white;
      padding: 20px;
      border: 1px solid #d7d7d7;
      border-radius: 4px;
      .asset-project-info{
        margin-bottom: 20px;
        .card-blk{
          clear: both;
          margin-bottom: 20px;
          img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            float: left;
            position: relative;
            top: 10px;
            &.lessor-logo{
              width: auto;
              height: 30px;
              border-radius: 0;
            }
          }
          label{
            font-size: 12px;
            font-family: $ibmplexRegular;
            color: #7f8fa4;
          }
          h6{
            font-size: $font14;
            font-family: $ibmplexMedium;
            color: $black;
          }
          >div{
            margin-left: 40px;
          }
        }
      }
      table{
        width: 100%;
        tbody{
          tr{
            &:last-child{
              border-bottom: none;
            }
          }
        }
        tr{
          border-bottom: 1px solid #d7d7d7;
          th{
            color: #7f8fa4;
            font-size: $font12;
            font-family: $ibmplexMedium;
            padding: 8px 6px;
          }
          td{
            padding: 16px 6px;
            h4{
              font-size: 20px;
              font-family: $ibmplexRegular;
              color: $black;
            }
            .user-info{
              img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 6px;
              }
              font-size: $font14;
              font-family: $ibmplexRegular;
              color: $black;
            }
            &:last-child{
              span{
                font-size: $font14;
                font-family: $ibmplexRegular;
                color: #438bf7;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .cor-popover{
    min-width: 680px !important;
    table{
      width: 100%;
      thead{
        th{
          font-size: 12px;
          font-family: $ibmplexMedium;
          padding: 8px;
        }
      }
      tbody{
        td{
          font-size: 12px;
          font-family: $ibmplexRegular;
          padding: 8px;
          &.user-info{
            img{
              width: 30px;
              height: 30px;
              border-radius: 50%;
              float: left;
            }
            >div{
              margin-left: 36px;
              h4{
                font-size: $font12;
                color: $black
              }
              .cor-detial-link{
                font-size: 10px;
                font-family: $ibmplexRegular;
                color: #337ab7
              }
            }
          }
        }
      }
    }
  }
  .core-details-block{
    margin: 40px 0 40px 20px;
    .core-wrapper{
      position: relative;
      min-height: 70vh;
      .core-heading{
        background-color: #fff;
        margin: 0;
        border: 1px solid #dfe2e5;
        .detail-left-block{
          padding-right: 0;
          padding-left: 0;
          border-right: 1px solid #dfe2e5;
          .list-row{
            margin: 0;
            width: 100%;
            .list-column{
              display: flex;
              padding: 21px 8px;
              border-left: 1px solid #dfe2e5;
              &:first-child{
                border-left: none;
              }
              p{
                text-align: left;
                font-family: $ibmplexSemiBold;
                font-size: 12px;
                color: #7f8fa4;
              }
            }
          }
        }
        .outer-detail-right-block{
          padding: 0;
          .report-block{
            padding: 4px;
            border-bottom: 1px solid #dfe2e5;
            font-family: $ibmplexSemiBold;
            font-size: 12px;
            color: #7f8fa4;
            text-align: center;
          }
          .detail-right-block{
            ul{
              margin: 0;
              width: 100%;
              li{
                width: 12.5%;
                margin: 0;
                float: left;
                text-align: center;
                height: 100%;
                padding: 8px;
                border-left: 1px solid #dfe2e5;
                p{
                  font-family: $ibmplexSemiBold;
                  font-size: 12px;
                  color: #7f8fa4;
                  text-align: center;
                  text-transform: capitalize;
                }
                &:first-child{
                  border-left: none;
                }
              }
            }
          }
        }
      }
      .core-details{
        margin: 0;
        background-color: #fff;
        border: 1px solid #dfe2e5;
        border-top: none;
        .margin-row{
          margin: 0;
          .db-height-block{
            padding: 0;
            .list-data-row{
              margin: 0;
              height: 100%;
              .list-data-col{
                padding: 21px 8px;
                border-right: 1px solid #dfe2e5;
              }
            }
            .name-image-block{
              width: 100%;
              img{
                width: 46px;
                margin-right: 15px;
                display: block;
                object-fit: cover;
                max-height: 20px;
              }
              .lessor-info{
                .project-num{
                  font-size: 10px;
                  word-break: break-all;
                  color: #7f8fa4;
                }
                h4{
                  line-height: 16px;
                  a{
                    word-break: break-all;
                    word-break: break-all;
                    font-family: $ibmplexSemiBold;
                    font-size: 14px;
                    color: #000;
                  }
                }
                .lead-by{
                  font-size: 12px;
                  font-family: $ibmplexRegular;
                  word-break: break-all;
                  color: #7f8fa4;
                  span{
                    color: $black;
                  }
                }
              }
            }
            .operator-name{
              font-size: 14px;
              color: $black;
              font-family: $ibmplexRegular;
            }
            .cor-engineer-info{
              .engine-cor-detail{
                font-size: 14px;
                color: $black;
                font-family: $ibmplexRegular;
              }
              .cor-detial-link{
                font-size: 10px;
                font-family: $ibmplexRegular;
                color: #337ab7
              }
            }
            .status-block{
              .status-single{
                width: 100%;
                margin-bottom: 8px;
                &:last-child{
                  margin-bottom: 0;
                }
                h4{
                  line-height: 12px;
                  span{
                    font-size: 14px;
                    font-family: $ibmplexMedium;
                  }
                }
                a{
                  font-size: 12px;
                  font-family: $ibmplexRegular;
                }
              }
            }
          }
        }
        .db-left-row{
          display: flex;
          display: -webkit-flex;
          flex-flow: wrap;
          -webkit-flex-flow: wrap;
          height: 100%;
          .db-left-height-block{
            ul{
              border-bottom: 1px solid #dfe2e5;
              height: 100%;
              li{
                width: 12.5%;
                text-align: center;
                padding: 8px;
                border-right: 1px solid #dfe2e5;
                &:last-child{
                  border-right: none;
                }
                span{
                  margin: 0 auto;
                  font-size: 14px;
                  font-family: $ibmplexRegular;
                  color: $black;
                }
              }
            }
            &:last-child ul{
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  .technical-inspection-cn{
    .projects-cor{
      padding: 20px;
      .main-row{
        border: 1px solid #ddd;
        }

      .list-heading{
        border-bottom: 1px solid #dfe2e5;
        background-color: #FFFFFF;
        border-right: 1px solid #dfe2e5;
        h4{
          color: #7f8fa4;
          font-family:$ibmplexSemiBold;
          font-size: 14px;
          padding: 16px 15px;

        }
        &.user-name-block{
          padding: 8px;
          height: 48px;
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 5px;
            // object-fit: contain;
            border: 1px solid #ddd;
          }
          h5 {
          color: #354052;
          font-family: $ibmplexSemiBold;
          font-size: 14px;
          }

          .btn-text{
            font-family: $ibmplexRegular;
            font-size: 12px;
            color: #000000;
            padding: 6px 30px;
            margin-right: 10px;
            margin-left: 0px;
            background: #fff;
            border: 1px solid #2670ca;
          }
          .btn-reject{
            font-family: $ibmplexRegular;
            font-size: 12px;
            color: red;
            padding: 6px 30px;
            margin-right: 10px;
            margin-left: 0px;
            background: #fff;
            border: 1px solid red;
          }

        }
      }
      .genral-block{
        background-color: #F0F2F6;
        padding: 12px;
        h3 {
          font-family:$ibmplexSemiBold;
          font-size: 13px;
          color: #7f8fa4;
          text-transform: uppercase;
      }
      }
      .question-block{
        border-right: 1px solid #dfe2e5;
        border-bottom: 1px solid #dfe2e5;
        height: 62px;
        padding: 20px;
        h4{
          font-family: $ibmplexSemiBold;
          font-size: 14px;
          color: #354052;
          text-align: left;
        }
      }
      .answer-wrapper {
        margin: 0;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        overflow: scroll;
        &::-webkit-scrollbar{
          display: block!important;
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb{
          background: #354052;
        }
       }
       .flex-wraper{
        display: flex;
        display: -webkit-flex;
        flex-flow: nowrap;
        -webkit-flex-flow: nowrap;
        align-items: center;
        width: 100%;
       }

      .answere-block{
        justify-content: center;
        border-right: 1px solid #dfe2e5;
        border-bottom: 1px solid #dfe2e5;
        height: 62px;
        ul{
          margin-right: 10px;
          justify-content: center;
          li {
            margin-right: 5px;
            padding: 0;
            label {
              position: relative;
              padding: 3px 8px;
              border: 1px solid #000;
              text-align: center;
              display: inline-block;
              max-width: 100%;
              margin-bottom: 5px;
              font-weight: bold;
              cursor: pointer;
              &.active{
                background: #979797;
                color: #FFFFFF;
              }
          }
          }
          .list-inline {
            li{
              display: inline-block;
            }
            padding-left: 0;
            list-style: none;
            margin-left: -5px;
        }
        }
        textarea {
          resize: none;
          width: 100%;
          padding: 5px;
          border: 1px solid #ddd;
      }
      }
    }
    .technical-projects{
      .archived-project{
        background-color: #56B4DE;
        color: #fff !important;
        padding: 2px 10px;
        font-size: 11px;
        font-weight: 700;
        display: block;
        text-align: center;
      }
      .projects-tab{
        margin-bottom:10px;
	      margin-top:20px;
      }
      table{
        .project-info{
          h4{
            @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexSemiBold, $margin: 0 0 6px 0);
          }
          p{
            background: #E8E8E8;
            height: 4px;
            position: relative;
            span{
              position: absolute;
              left: 0;
              top:0;
              z-index: 0;
              background: #3CE966;
              height: 100%;
            }
          }
        }
        .operator-info{
          img{
            width:85px;
          }
        }
        .lead-info{
          .multi-leads{
            img{
              margin-right: -16px;
           }
          }
          ul{
            li{
              img{
                width: 35px;
                height: 35px;
                border-radius: 50%;
              }

              .more-lead-info{
                display: inline-block;
                background: #a8cbf7;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                position: relative;
                top: 4px;

                b{
                  position: absolute;
                  font-family: $ibmplexRegular;
                  font-size: 11px;
                  font-weight: 300;
                  transform: translate(-50%, -50%);
                  top:50%;
                  left:50%;
                }
              }
            }
          }
        }
      }
    }
    .project-crud-form{
      .cke_button__anchor_icon, .cke_button__image_icon, .cke_button__maximize, .cke_button__source, .cke_button__about_icon{
        display: none;
      }
      .add-contacts{
        h4{
          font-size: $font14;
          font-family: $ibmplexMedium;
          color: $black;
          margin-bottom: 16px;
          a{
            display: inline-block;
            font-size: $font12;
            font-family: $ibmplexRegular;
            color: $linkBlue;
            margin-left: 6px;
            cursor: pointer;
          }
        }
        table{
          input[type="text"]{
            background: transparent;
          }
        }
      }
      .add-ck-editor{
        h4{
          font-size: $font14;
          font-family: $ibmplexMedium;
          color: $sectionL1Header;
          margin-bottom: 10px;

        }
      }
    }
    .workorder-crud-form{
      .radio-block{
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #000000;
        font-weight: 300;
        input[type="radio"]{
          margin-right:8px;
        }

      }
      .grouped-blocks{
        .inner-grouped-block{
          background-color: #ecebeb;
          padding: 20px;
          margin: 0 0 20px 0;
          h4{
            font-family: $ibmplexSemiBold;
            font-size: 14px;
            margin-bottom:12px;
            color: #691ad3;
          }
          input[type="text"], input[type="number"]{
            background: transparent;
            &:disabled{
              background: rgba(0,0,0,0.1);
            }
          }
        }


      }

    }
    &.project-view{
      padding: 0px 0 60px 0;
      .archived-project-banner{
        text-align: center;
        background: rgb(255, 195, 0);
        color: #000;
        margin-right: -15px;
        font-size: 14px;
        margin-left: -15px;
        border: 1px solid rgb(226, 170, 1);
        a{
          color: #337ab7;
        }
      }
      .technical-project-view{
        padding-bottom: 70px;
        .no-records-found{
          margin:0;
        }
        .view-header{
          margin-bottom: 10px;
          background: #fff;
          padding: 10px 15px;
          border-bottom: 1px solid #d7d7d7;
          .project-nav{
            img{
              margin-right:4px;
              position: relative;
              top: -2px;
            }
          }
          .project-edit{
            margin-top:5px;
            li{
              // margin-right: 15px;
              h2{
                font-size: 20px;
                font-family: $ibmplexSemiBold;
                color: $black;
                margin-bottom: 5px;
              }
              p{
                background: #E8E8E8;
                height: 4px;
                position: relative;
                span{
                  position: absolute;
                  left: 0;
                  top:0;
                  z-index: 1;
                  background: #3CE966;
                  height: 100%;
                }
              }
              button{
                font-size: 12px;
                font-family: $ibmplexRegular;
                color: $linkBlue;
                background: transparent;
                .edit-proj{
                  width: 12px;
                  margin-right: 5px;

                }
              }
            }

          }
          .lessee-info{
            float: right;
            li{
              margin-left:30px;
              .projectform-status{
                height: 20px;
                text-align: center;
                position: relative;
                left: 9px;

              }
              .project-status{
                position: relative;
                top: -2px;
              }
              img{
                height: 28px;
              }
              h6{
                height: 34px;
              }
            }

          }
        }
        .project-title-add{
          font-size: $font14;
          font-family: $ibmplexRegular;
          color: $black;
          margin-bottom: 20px;
          button{
            margin-left: 10px;
            padding: 8px 20px;
            @extend %primary-botton;
          }
          .add-asset-dd{
            box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
            border-radius: 4px;
            &.show{
              .dropdown-toggle{
                background: transparent;
                color: #000;
                &:focus, &:active, &:hover{
                  background: #2f8cff;
                  color: #fff;
                  border: 1px solid #2f8cff;
                  font-size: 12px;
                }
              }
            }
           .dropdown-toggle{
              background: #2f8cff;
              color: #fff;
              border: 1px solid #2f8cff;
              font-size: 12px;

              &:focus, &:active, &:hover{
                box-shadow: 0 0 0;
                background: #2f8cff;
                color: #fff;
                border: 1px solid #2f8cff;
                font-size: 12px;
              }

            }
            .dropdown-menu{
              background:$color_fff;;
              border:1px solid #e9e9e9;
              box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
              border-radius:3px;
              padding:10px;
              .dropdown-item{
                cursor: pointer;
                padding: 0 0 10px;
                border-bottom: 1px solid #ededed;
                margin-bottom: 10px;
                &:last-child{
                  border-bottom: none;
                  margin-bottom:0;
                  padding-bottom:0;
                }
                img{
                  width:12px;
                  margin-right:10px;
                }
              }
            }
          }
          .download-block{
            button{
              background: $color_cff !important;;
              border: none;
              font-family:$ibmplexRegular;
              font-size:12px;
              color:#fff !important;
              &:focus, &:hover, &:active, &.active{
                box-shadow: 0 0 0 !important;
                background: $color_cff!important;
                color: #fff!important;
              }
              span{
                margin-right:2px;
                img{
                  margin-right: 2px;
                  position: relative;
                  top: -1px;
                }
              }
              &.dropdown-toggle::after{
                position: relative;
                top: 1px;
              }
            }
            .dropdown-menu{
              background:$color_fff;;
              border:1px solid #e9e9e9;
              box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
              border-radius:3px;
              padding:10px;
              .dropdown-item{
                cursor: pointer;
                padding: 0 0 10px;
                border-bottom: 1px solid #ededed;
                margin-bottom: 10px;
                background: transparent !important;
                border: none;
                font-family:$ibmplexRegular;
                font-size:12px;
                color:$color_cff !important;
                &:focus, &:hover, &:active, &.active{
                  box-shadow: 0 0 0;
                  background: transparent !important;
                  color: $color_cff !important;
                }
                &:last-child{
                  border-bottom: none;
                  margin-bottom:0;
                  padding-bottom:0;
                }
                img{
                  width:20px;
                  margin-right:10px;
                }
              }
            }
          }
        }
        .launch-work-order{
          font-size: $font14;
          font-family: $ibmplexRegular;
          color: $black;
          padding:10px 10px;
          background: #F3F6F9;
          text-align: right;
          button{
            margin-left: 10px;
            padding: 3px 12px;
            @extend %primary-botton;
          }
        }

        .project-view-asset{
          margin-bottom: 20px;
          .card{
            border: none;
            border-radius: none;
            background: #FAFAFA;
            .card-body{
              background: #FAFAFA;
              padding: 10px 0;
              border:1px solid #d7d7d7;
            }
          }

          table{
            width: 100%;
            tr{
              position: relative;
              cursor: pointer;
              td{
                padding:5px 10px 15px 10px;
                .collapse-img{
                  position: absolute;
                  top:50%;
                  right:30px;
                  transform: translate(-50%, -50%);
                  width:12px;
                }
                h6{
                  font-size: 14px;
                }
              }
            }
            .review-wrapper{
              width:75px;
              position: relative;
              display: block;
              cursor: pointer;
              text-align: center;
              padding: 5px;
              transition: all 0.2s ease;
             img{
              width:24px;
              vertical-align: middle;
              .hover-hide{
                display: inline;
              }
              .hover-show{
                display: none;
              }
            }
            .issue-count {
              position: absolute;
              width: 24px;
              background: transparent;
              right: 18px;
              top: 56%;
              transform: translate(-50%, -50%);
              left: 46%;
              font-size: 11px;
              color: white
          }
          .review-title {
            font-family: $ibmplexRegular;
            font-size: 12px;
            color: #2f8cff;
            line-height: 14px;
            margin-top: 5px;
            display: block;
          }
          }

            .progess-info-widget{
              h4, h6{
                font-family: $ibmplexMedium;
                color: $black;
                margin-bottom: 5px;
              }
              h4{
                font-size: 18px;
                .edit-asset{
                  font-size: 12px;
                  font-family:$ibmplexRegular;
                  color: #2f8cff;
                  background: transparent;
                  margin-left: 4px;
                  img{
                    width: 10px;
                    display: inline-block;
                    margin-right:5px;
                  }
                }
              }
              h6{
                font-size: $font16;
              }
              p{
                background: #E8E8E8;
                height: 4px;
                position: relative;
                span{
                  position: absolute;
                  left: 0;
                  top:0;
                  z-index: 1;
                  background: #3CE966;
                  height: 100%;
                }
              }
            }
            &.header-info{
              background: $white;
              position: relative;
              z-index: 1;
              tr{
                td{
                  &:first-child{
                    img.asset-type{
                      float: left;
                      width: 46px;
                      position: relative;
                      top: 3px;
                    }
                    .progess-info-widget{
                      margin-left: 60px;
                    }
                  }
                }
              }
            }
          }
          .forms-attach-list{
            tr{
              border-bottom: 1px solid #E5E5E5;
              &:last-child{
                border-bottom: none;
              }
              .assigned-engineer{
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin-right: 5px;

              }
              .change-engineer{
                font-family:$ibmplexRegular;
                color: #2f8cff;
                font-size: 12px;
                margin-left: 10px;
                img{
                  width: 10px;
                  display: inline-block;
                  margin-right: 5px;
                }
              }
              .no-forms-attached{
                text-align: center;
                padding: 37px 0;
              }

            }
          }

        }
        .project-date-card{
          background: $white;
          text-align: center;
          padding:20px 15px;
          margin-bottom: 20px;
          p{
            font-size: $font12;
            font-family: $ibmplexRegular;
            color: $black;
          }
          h6{
            font-size: $font16;
            font-family: $ibmplexMedium;
            color: $black;
          }
        }
        .user-manages-card{
          background: $white;
          padding: 15px;
          margin-bottom: 20px;
          >div{
            clear: both;
            margin-bottom: 40px;
            &:last-child{
              margin-bottom: 0;
            }
          }
          h4{
            font-size: $font14;
            font-family: $ibmplexMedium;
            color: #691ad3;
            margin-bottom: 20px;
            button{
              font-size: $font12;
              font-family: $ibmplexRegular;
              color: $linkBlue;
              margin-left: 6px;
              cursor: pointer;
              background: #fff;
            }
          }
          ul{
            li{
              &:hover{
                .change-lead, .remove-member{
                  display: inline-block;
                }
              }
              margin-bottom:20px;
              &:last-child{
                margin-bottom:0;
              }
              .user-img{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                float: left;
              }
              .change-lead{
                display: none;
                font-size: 12px;
                font-family: Conv_IBMPlexSans-Regular;
                background: transparent;
                margin-left: 8px;
                color: red;
              }
              .remove-member{
                display: none;
                margin-left: 7px;
                position: relative;
                top: -2px;
                font-size: 12px;
                span{
                  color: red;
                }
              }
              >div{
                margin-left: 20px;
                h5{
                  font-size: $font14;
                  font-family: $ibmplexRegular;
                  color: $black
                }
                p{
                  font-size: $font12;
                  font-family: $ibmplexRegular;
                  color: #9A9A9A;
                }
              }
            }
          }
        }
        .add-user-types{

          input{
            border: 1px solid #dddddd !important;
            border-radius: 3px;
            margin: 0 20px 20px 20px !important;
            width:88% !important;
          }
          ul{
            li{
              cursor: pointer;
              position: relative;
              padding: 5px 0 20px;
              font-size: $font14;
              font-family: $ibmplexRegular;
              color: $black;
              .unselected-checkbox{
                background-color: #ebebeb;
                display: inline-block;
                width: 18px;
                height:18px;
                border-radius: 50%;
                position: absolute;
                right: 5px;

              }
              img{
                &.user-img{
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  margin-right: 8px;

                }
                &.checkbox-img{
                  float: right;
                  position: absolute;
                  right: 5px;
                  width:18px;
                }

              }
            }
          }
        }
      }
    }

  }

  .form-te005-cn{
    .general-detail-block{
      margin-bottom:30px;
      .edit-img-blk{
        position: absolute;
        right: 0;
        top: -15px;
        cursor: pointer;
      }
      h6{
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #000000;
      }
    }
    .llp-detail-block{
      .table-ui-component{
        margin-bottom:30px;
        input[type = "text"]{
          border-bottom:1px solid #d7d7d7;
        }
        .Select-control{
          border-bottom:1px solid #d7d7d7;

        }
      }
    }
    .dis-assembly {
      input[type = "text"]{
        border-bottom:1px solid #d7d7d7;
      }
    }
    .tableInspec{
      font-family: $ibmplexRegular;
      font-size: 12px;
      color: #2f8cff;
      .tech-heading-block {
        position: relative;
        a{
          &.view-all{
            font-family: $ibmplexRegular;
            font-size: 12px;
            color: #2f8cff;
            position: absolute;
            right: 1px;
          }
        }
      }
      &.lsv-block{
        th{
          &:first-child{
            width: 48%;
          }
        }
      }
      &.dis-assembly{
        th{
          &:nth-child(3){
            width: 60%;
          }
        }
      }
      &.llp-detail-block{
        th, td{
          &:last-child{
            text-align: center;
          }
        }
      }
      .bulk-operation{
        position: relative;
        text-align: right;
        margin-bottom: 8px;
        a{
          cursor: pointer;
        }
        input[type="file"]{
          position: absolute;
          right:0;
          width: 130px;
          opacity: 0;
        }
      }
    }
    .shopvisit-acctacment-row{
      .profile-pic{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 8px;
      }
      input[type="text"]{
        background: transparent;
      }
      .download-block{
        a{
          margin-right: 12px;
          display: inline-block;
        }
      }
    }
    .workscope-button{
      text-align: right;

      .add-item{
        font-family: $ibmplexRegular;
        font-size: 12px;
        color: #2f8cff;
      }
    }
  }

  .form-te006-cn{

    .dis-assembly {
      input[type = "text"]{
        border-bottom:1px solid #d7d7d7;
      }
    }
    .tech-heading-block{
      font-family: $ibmplexRegular;
      font-size: 16px;
      color: #691ad3;
      margin: 21px 0 21px;
      span{
        font-family: $ibmplexRegular;
        font-size: 16px;
        color: #2f8cff;
        display: inline-block;
        margin-left:20px;
        cursor: pointer;
        img{
          display:inline-block;
          margin-right: 3px;
          width: 11px;
        }
      }
      a{
        &.add-section{
          font-family: $ibmplexRegular;
          font-size: 12px;
          color: #2f8cff;
          position: absolute;
          right: 25px;
          padding-top: 30px;
        }
      }
    }

    .remove-review-section{
      color: red;
      font-size: 12px;
      font-family: $ibmplexRegular;
      margin: 5px;
    }
    .collapse-editor{
      background: #f1efef;
      padding: 2px;
      border-radius: 5px;
      border: 1px solid #d3d3d3;
      margin-bottom: 10px;
      .collapse-header{
        padding: 5px;
        .review-title, .review-index{
          border-bottom:1px solid #d3d3d3;
        }
        .review-index{
          width: 5%;
          margin-right: 10px;
        }
        .review-title{
          width: 95%;
        }
        a{
          color: #d3d3d3;
          float: right;
          font-size: 24px;
          padding-right: 5px;
          font-family: $ibmplexRegular;
        }
      }
    }

    .tableInspec{
      font-family: $ibmplexRegular;
      font-size: 12px;
      color: #2f8cff;
      .tech-heading-block {
        position: relative;
        font-family: $ibmplexRegular;
        font-size: 16px;
        color: #691ad3;
        margin: 8px 0 21px;
        a{
          &.view-all{
            font-family: $ibmplexRegular;
            font-size: 12px;
            color: #2f8cff;
            position: absolute;
            right: 1px;
          }
        }
      }
      &.dis-assembly{
        th{
          &:nth-child(3){
            width: 60%;
          }
        }
      }
      &.llp-detail-block{
        th, td{
          &:last-child{
            text-align: center;
          }
        }
      }
      .bulk-operation{
        position: relative;
        text-align: right;
        margin-bottom: 8px;
        a{
          &.add-attachment{
            cursor: pointer;
          }
        }
        input[type="file"]{
          position: absolute;
          right:0;
          width: 200px;
          cursor: pointer;
          opacity: 0;
        }
      }
    }
    .shopvisit-acctacment-row{
      .profile-pic{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 8px;
      }
      input[type="text"]{
        background: transparent;
        border-bottom:1px solid #d7d7d7;
      }
      .download-block{
        a{
          margin-right: 12px;
          display: inline-block;
        }
      }
    }
    .workscope-button{
      text-align: right;

      .add-item{
        font-family: $ibmplexRegular;
        font-size: 12px;
        color: #2f8cff;
      }
    }
  }
  .technical-review-model{
    padding-top: 110px;
    position: relative;
    .view-issues-btn{
      position: fixed;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
    .review-header{
      .form-header{
        .select-form-block{
          .dropdown{
            h2{
              font-size: 20px;
              color: #000;
              width: 100%;
              text-align: left;
              padding: 0;
              margin-bottom: 4px;
              font-family: $ibmplexMedium;
            }
            p{
              font-family: $ibmplexRegular;
              font-size: 11px;
              color: #a0a0a0;
            }
          }
        }
      }
    }
    .form-t001, .form-t002{
      border: 1px solid #d7d7d7;
      width: 70%;
      margin: 20px auto;
      background: $white;
      padding: 5px 40px;
      .section-cn{
        padding: 30px 0;
        border-bottom: 1px solid #d7d7d7;
        min-height: 200px;
        &:last-child{
          border-bottom: none;
        }
        h3{
          font-size: 16px;
          font-family: $ibmplexMedium;
          margin-bottom: 16px;
        }
        ul{
          li{
            margin-bottom: 20px;
            position: relative;
            .aircraft-images{
              object-fit: contain;
              height: 300px;
              width: 400px;
              display: block;
              margin: 0 auto;
            }
            .comment-icon,.issue-number{
              position: absolute;
              right: 7px;
              top: 0;
              cursor: pointer;
              background: #b0bfd1;
              border-radius: 3px;
              padding: 3px 6px;
              min-width: 30px;
              display: none;
              .grey-icon{
                display: inline-block;
              }
              .blue-icon{
                display: none;
              }
              &:hover{
                .grey-icon{
                  display: none;
                }
                .blue-icon{
                  display: inline-block;
                }
                background: #2cadff;
              }
            }
            .issue-number{
              border-radius: 50%;
              z-index: 0;
              cursor: pointer;
              background: #B0BFD1;
              box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
              min-width: 28px;
              min-height: 28px;
              font-size: 12px;
              color: #fff;
              margin-right: 6px;
              display: inline-block;
              span{
                position: relative;
                top: 3px;
              }
              &.active{
                background: #2cadff;
              }
            }
            &:hover{
              .comment-icon{
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .form-t003{
      width: 70%;
      margin: 20px auto;
      h3{
        font-size: 24px;
        font-family: $ibmplexRegular;
        color: #000000;
        margin-bottom: 10px;
      }
      .form-t003-section{
        border: 1px solid #d7d7d7;
        background: $white;
        padding: 20px 40px;

        .audit-cn{
          margin-bottom: 30px;
          h5{
            font-size: 18px;
            font-family: $ibmplexRegular;
            color: #000000;
            margin-bottom: 10px;
          }
          table{
            width: 100%;
            border: 1px solid #d7d7d7;
            tr{
              border-bottom: 1px solid #d7d7d7;
              &:last-child{
                border-bottom: none;
              }
              td{
                padding: 10px;
                font-size: 14px;
                font-family: $ibmplexRegular;
                &.comments-wrapper{
                  position: relative;
                  min-width: 70px;
                  text-align: center;
                  .comment-icon,.issue-number{
                    cursor: pointer;
                    background: #b0bfd1;
                    border-radius: 3px;
                    padding: 0 6px;
                    display: none;
                    .grey-icon{
                      display: inline-block;
                      height: 14px;
                    }
                    .blue-icon{
                      display: none;
                      height: 14px;
                    }
                    &:hover{
                      .grey-icon{
                        display: none;
                      }
                      .blue-icon{
                        display: inline-block;
                      }
                      background: #2cadff;
                    }
                  }
                  .issue-number{
                    border-radius: 50%;
                    z-index: 0;
                    cursor: pointer;
                    background: #B0BFD1;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
                    font-size: 12px;
                    color: #fff;
                    margin-right: 6px;
                    display: inline-block;
                    span{
                      position: relative;
                      top: 3px;
                    }
                    &.active{
                      background: #2cadff;
                    }
                  }
                }
                &:first-child{
                  width: 50%;
                }
                &:nth-child(2){
                  width: 30%;
                }
                &:nth-child(3){
                  width: 10%;
                  text-align: center;
                }
              }
              &:hover{
                td{
                  &.comments-wrapper{
                    .comment-icon{
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-t005{
      border: 1px solid #d7d7d7;
      width: 70%;
      margin: 20px auto;
      background: $white;
      padding: 20px 40px;
      .section-cn{
        margin-bottom: 20px;
        border-bottom: 1px solid #d7d7d7;
        h3{
          font-size: 20px;
          font-family: $ibmplexRegular;
          color: #bd10e0;
          margin-bottom: 16px;
        }
        h5{
          font-size: 12px;
          font-family: $ibmplexRegular;
          color: #5d5d5d;
        }
        .label-value{
          font-size: 14px;
          font-family: $ibmplexMedium;
        }
        .col-md-3{
          .comment-icon,.issue-number{
            position: absolute;
            right: 20px;
            top: 5px;
            cursor: pointer;
            background: #b0bfd1;
            border-radius: 3px;
            padding: 3px 6px;
            min-width: 30px;
            display: none;
            .grey-icon{
              display: inline-block;
            }
            .blue-icon{
              display: none;
            }
            &:hover{
              .grey-icon{
                display: none;
              }
              .blue-icon{
                display: inline-block;
              }
              background: #2cadff;
            }
          }
          .issue-number{
            border-radius: 50%;
            z-index: 0;
            cursor: pointer;
            background: #B0BFD1;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
            min-width: 28px;
            min-height: 28px;
            font-size: 12px;
            color: #fff;
            margin-right: 6px;
            display: inline-block;
            span{
              position: relative;
              top: 3px;
            }
            &.active{
              background: #2cadff;
            }
          }
          &:hover,&:active{
            .comment-icon{
              display: inline-block;
            }
          }
          &:after{
            content: '';
            position: absolute;
            height: 100%;
            width: 1px;
            right: 5px;
            top: 1px;
            background-color: #d7d7d7;
          }
          &:last-child{
            &:after{
              content: none;
            }
          }
          &:nth-child(4n){
            &:after{
              content: none;
            }
          }
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
    .form-t006{
      border: 1px solid #d7d7d7;
      width: 70%;
      margin: 20px auto;
      background: $white;
      padding: 20px 40px;
      .contact-list{
        border-bottom: 1px solid #d7d7d7;
        padding: 15px 0;
        position: relative;
        &:last-child{
          border-bottom: none;
        }
        h4{
          font-size: 18px;
          font-family: $ibmplexRegular;
        }
        p{
          font-size: 14px;
          font-family: $ibmplexRegular;
          color: #368ffb;
        }
        ul{
          li{
            font-size: 12px;
            font-family: $ibmplexRegular;
            border-right: 1px solid #d7d7d7;
            &:last-child{
              border-right: none;
            }
          }
        }
        .comment-icon,.issue-number{
          position: absolute;
          right: 20px;
          top: 50%;
          cursor: pointer;
          background: #b0bfd1;
          border-radius: 3px;
          padding: 3px 6px;
          min-width: 30px;
          display: none;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          .grey-icon{
            display: inline-block;
          }
          .blue-icon{
            display: none;
          }
          &:hover{
            .grey-icon{
              display: none;
            }
            .blue-icon{
              display: inline-block;
            }
            background: #2cadff;
          }
        }
        .issue-number{
          border-radius: 50%;
          z-index: 0;
          cursor: pointer;
          background: #B0BFD1;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
          min-width: 28px;
          min-height: 28px;
          font-size: 12px;
          color: #fff;
          margin-right: 6px;
          display: inline-block;
          span{
            position: relative;
            top: 3px;
          }
          &.active{
            background: #2cadff;
          }
        }
        &:hover,&:active{
          .comment-icon{
            display: inline-block;
          }
        }
      }
    }
    .form-t007{
      border: 1px solid #d7d7d7;
      width: 70%;
      margin: 20px auto;
      background: $white;
      padding: 10px;
      .comment-icon,.issue-number{
        position: absolute;
        margin-left: 15px;
        top: 50%;
        cursor: pointer;
        background: #b0bfd1;
        border-radius: 3px;
        padding: 3px 6px;
        min-width: 30px;
        display: none;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        .grey-icon{
          display: inline-block;
        }
        .blue-icon{
          display: none;
        }
        &:hover{
          .grey-icon{
            display: none;
          }
          .blue-icon{
            display: inline-block;
          }
          background: #2cadff;
        }
      }
      .issue-number{
        border-radius: 50%;
        z-index: 0;
        cursor: pointer;
        background: #B0BFD1;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
        min-width: 28px;
        min-height: 28px;
        font-size: 12px;
        color: #fff;
        margin-right: 6px;
        display: inline-block;
        span{
          position: relative;
          top: 3px;
        }
        &.active{
          background: #2cadff;
        }
      }
      .technical-inspection-cn{
        margin: 0;
        padding: 15px;
        .form-t004-cn{
          margin: 0;
          padding: 15px;
          position: relative;
          min-height: auto;
          .model-wrapper{
            margin: 15px auto 0;
          }
          .indicater-detail-block{
            top: 15px;
          }
        }
        .inspection-info-row{
          >div{
            margin: 10px 0;
          }
          h2{
            font-family: $ibmplexRegular;
            color: #4a90e2;
            font-size: 14px;
            line-height: 25px;
            position: relative;
            &:hover{
              .comment-icon{
                display: inline-block;
              }
            }
          }
          p{
            font-family: $ibmplexMedium;
            font-size: 16px;
            line-height: 20px;
            color: #354052;
            position: relative;
            &:hover{
              .comment-icon{
                display: inline-block;
              }
            }
          }
        }
        .inspection-list{
          margin-top: 30px;
          >div{
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #C5C5C5;
            &:last-child{
              border-bottom: none;
            }
            h2{
              font-size: 16px;
              color: #2f8cfe;
              font-family: $ibmplexMedium;
              line-height: 20px;
              margin-bottom: 15px;
              text-decoration: underline;
              position: relative;
              &:hover{
                .comment-icon{
                  display: inline-block;
                }
              }
            }
            .finding-row{
              >p{
                span{
                  border-radius: 50%;
                  background-color: #488cc8;
                  color: #ffffff;
                  font-size: 12px;
                  padding: 5px
                }
              }
              table{
                margin: 10px 0 20px;
                td,th{
                  margin: 5px 0;
                  font-size: 12px;
                  font-family: $ibmplexRegular;
                  b{
                    font-size: 12px;
                    font-family: $ibmplexBold;
                    min-width: 120px;
                    display: inline-block;
                    margin-bottom: 5px;
                  }
                  span{
                    font-size: 12px;
                    font-family: $ibmplexRegular;
                    padding-left: 10px;
                  }
                  &:hover{
                    .review-btn-blk{
                      .comment-icon{
                        display: inline-block;
                      }
                    }
                  }
                }
                td{
                  &:last-child{
                    padding-right: 50px;
                  }
                }
                tr{
                  .review-btn-blk{
                    position: relative;
                    span{
                      padding: 3px 6px;
                    }
                  }
                }
              }
              .findings-img{
                width: 300px;
                height: 300px;
                margin: 0 auto 20px;
                display: block;
                object-fit: cover;
                max-width: 100%;
              }
              >div.col-md-6{
                .comment-icon,.issue-number{
                  top: 5px;
                  right: 0;
                }
                &:hover{
                  .comment-icon{
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-t009{
      border: 1px solid #d7d7d7;
      width: 70%;
      margin: 20px auto;
      background: $white;
      padding: 20px 40px;
      .robbery-list{
        margin-bottom: 30px;
        .serial-number{
          margin-bottom: 10px;
          span{
            width: 30px;
            height: 30px;
            display: inline-block;
            border-radius: 50%;
            color: #ffffff;
            background: #4a90e2 !important;
            text-align: center;
            position: relative;
            margin-right: 5px;
            font-family: $ibmplexMedium;
            padding-top: 5px;
            font-size: 14px;
          }
        }
        table{
          width: 100%;
          border: 1px solid #d7d7d7;
          tr{
            border-bottom: 1px solid #d7d7d7;
            &:last-child{
              border-bottom: none;
            }
            td{
              padding: 10px;
              font-size: 14px;
              font-family: $ibmplexRegular;
              position: relative;
              ul{
                li{
                  margin-right: 10px;
                  label{
                    font-size: 14px;
                    font-family: $ibmplexRegular;
                    color: #368ffb;
                  }
                  p{
                    font-size: 12px;
                    font-family: $ibmplexRegular;
                  }
                }
              }
              &:first-child{
                border-right: 1px solid #d7d7d7;
                width: 200px;
                background: #F9F9F9;
              }
              .comment-icon,.issue-number{
                cursor: pointer;
                background: #b0bfd1;
                border-radius: 3px;
                padding: 0 6px;
                display: none;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                .grey-icon{
                  display: inline-block;
                  height: 14px;
                }
                .blue-icon{
                  display: none;
                  height: 14px;
                }
                &:hover{
                  .grey-icon{
                    display: none;
                  }
                  .blue-icon{
                    display: inline-block;
                  }
                  background: #2cadff;
                }
              }
              .issue-number{
                border-radius: 50%;
                z-index: 0;
                cursor: pointer;
                background: #B0BFD1;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
                font-size: 12px;
                color: #fff;
                margin-right: 6px;
                display: inline-block;
                span{
                  position: relative;
                  top: 3px;
                }
                &.active{
                  background: #2cadff;
                }
              }
            }
            &:hover,&:active{
              td{
                .comment-icon{
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
    .form-t004{
      border: 1px solid #d7d7d7;
      width: 70%;
      margin: 35px auto;
      background: $white;
      padding: 15px 40px;
      .survey-info{
        margin-top: 15px;
        li{
          margin-bottom: 15px;
          h2{
            font-family: $ibmplexRegular;
            font-weight: 400;
            color: #727272;
            font-size: 12px;
            margin-bottom: 4px;
          }
          p{
            font-family: $ibmplexSemiBold;
            color: #000;
            font-size: 14px;
            margin: 0;
          }
        }
      }
      .table-of-content{
        h1{
          margin-bottom: 15px;
          span{
            font-family: $ibmplexSemiBold;
            color: #337ABB;
            font-size: 18px;
            border-bottom: 1px solid #dfe2e5;
            display: inline;
            padding: 4px 20px;
          }
        }
        .table-content-wrapper{
          border: 1px solid #dfe2e5;
          border-radius: 4px;
          margin-bottom: 30px;
          .table-content-single{
            padding: 10px;
            border-bottom: 1px solid #dfe2e5;
            ul{
              li{
                font-family: $ibmplexRegular;
                letter-spacing: 0.4px;
                line-height: 25px;
                color: #727272;
                font-size: 16px;
                ul{
                  margin-top: 10px;
                  margin-left: 10px;
                  li{
                    font-family: $ibmplexRegular;
                    letter-spacing: 0.4px;
                    line-height: 25px;
                    color: #727272;
                    font-size: 14px;
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }
      .detail-block{
        .detail-single{
          .detail-title{
            font-family: $ibmplexSemiBold;
            color: #337ABB;
            font-size: 18px;
            margin-bottom: 10px;
            .issues-group-count{
              margin-left: 10px;
              font-size: 10px;
              background: #8e8e8e;
              color: #fff;
              padding: 5px 10px;
              border-radius: 50%;
              cursor: pointer;
            }
          }
          .detail-inner{
            padding: 5px 15px 15px;
            h1,h2,h3,h4,h5,h6{
              font-family: $ibmplexSemiBold;
              font-size: 16px;
              margin-bottom: 5px;
            }
            p,li{
              font-family: $ibmplexRegular;
              font-size: 16px;
              margin-bottom: 5px;
            }
            table{
              width: 100%;
              background-color: transparent;
              tr{
                th,td{
                  border: 1px solid #dfe2e5;
                  padding: 10px;
                }
                th{
                  font-weight: 400;
                  font-family: $ibmplexSemiBold;
                  font-size: 16px;
                  color: #354052;
                  &.physical-part{
                    color: #337ABB;
                  }
                }
                td{
                  font-family: $ibmplexRegular;
                  color: #636363;
                  font-size: 14px;
                }
              }
            }
          }
          &.physical-conditions{
            table{
              width: 100%;
              background-color: transparent;
              tr{
                th,td{
                  border: 1px solid #dfe2e5;
                  padding: 10px;
                }
                th{
                  font-weight: 400;
                  font-family: $ibmplexSemiBold;
                  font-size: 16px;
                  color: #354052;
                  &.physical-part{
                    color: #337ABB;
                  }
                }
                td{
                  font-family: $ibmplexRegular;
                  color: #636363;
                  font-size: 14px;
                  &.physical-part{
                    color: #337ABB;
                  }
                  &.comments-wrapper{
                    position: relative;
                    min-width: 70px;
                    text-align: center;
                    .comment-icon,.issue-number{
                      cursor: pointer;
                      background: #b0bfd1;
                      border-radius: 3px;
                      padding: 0 6px;
                      display: none;
                      position: absolute;
                      right: 10px;
                      top: 50%;
                      transform: translateY(-50%);
                      -webkit-transform: translateY(-50%);
                      -moz-transform: translateY(-50%);
                      -ms-transform: translateY(-50%);
                      -o-transform: translateY(-50%);
                      .grey-icon{
                        display: inline-block;
                        height: 14px;
                      }
                      .blue-icon{
                        display: none;
                        height: 14px;
                      }
                      &:hover{
                        .grey-icon{
                          display: none;
                        }
                        .blue-icon{
                          display: inline-block;
                        }
                        background: #2cadff;
                      }
                    }
                    .issue-number{
                      border-radius: 50%;
                      z-index: 0;
                      cursor: pointer;
                      background: #B0BFD1;
                      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
                      font-size: 12px;
                      color: #fff;
                      margin-right: 6px;
                      display: inline-block;
                      span{
                        position: relative;
                        top: 3px;
                      }
                      &.active{
                        background: #2cadff;
                      }
                    }
                    &:hover,&:active{
                      .comment-icon{
                        display: inline-block;
                      }
                    }
                  }
                }

              }
            }
            .additional-remarks{
              font-family: $ibmplexRegular;
              color: #636363;
              font-size: 14px;
            }
          }
        }
      }
    }
    .reviewer-issue-drawer{
      right: 0;
      top: 0;
      height: 100%;
      background: #FFFFFF;
      width: 340px;
      z-index: 10000;
      .issues-listing{
        ul.list-unstyled{
          li{
            border-bottom: 1px solid #d7d7d7;
            padding: 15px;
            position: relative;
            cursor: pointer;
            span.issue-order-no{
              cursor: pointer;
              background: #B0BFD1;
              box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
              width: 28px;
              height: 28px;
              border-radius: 100%;
              position: relative;
              margin-right: 6px;
              display: inline-block;
              b{
                font-family: $ibmplexMedium;
                font-size: 10px;
                color: #fff;
                position: absolute;
                transform: translate(-50%, -50%);
                top: 55%;
                left: 50%;
              }
              &.resolved{
                background-color: #7ed321;
              }
            }
            h3{
              font-size: 16px;
              font-family: $ibmplexSemiBold;
              color: $black;
              margin-bottom: 4px;
            }
            .issued-date{
              margin-bottom: 10px;
              display: inline-block;
              color: #727272;
              font-size: 10px;
              font-family: $ibmplexSemiBold;
              line-height: 1;
              display: inherit;
            }
            p{
              font-family: $ibmplexRegular;
              font-size: 14px;
              color: #000000;
              word-wrap: break-word;
            }
            .resolved-issue{
              position: absolute;
              top: 0;
              right: 10px;
              background-color: #7ed321;
              border-radius: 4px;
              padding: 1px;
              img{
                background-color: #7ed321;
              }
            }
          }
        }
      }

    }
    .resolve-issue{
      border: 1px solid #7ed321;
      border-radius: 4px;
      padding: 5px 10px;
      font-size: 11px;
      font-family: $ibmplexSemiBold;
      position: absolute;
      top: 0;
      right: 13px;
      color: #7ed321;
      transition: all 0.4s ease;
      &:hover,&:active,&:focus{
        background-color: #7ed321;
        color: #ffffff;
        transition: all 0.4s ease;
      }
    }
    .re-open{
      border: 1px solid #2CADFF;
      border-radius: 4px;
      padding: 5px 10px;
      font-size: 11px;
      font-family: $ibmplexSemiBold;
      position: absolute;
      top: 0;
      right: 10px;
      color: #2CADFF;
      transition: all 0.4s ease;
      &:hover,&:active,&:focus{
        background-color: #2CADFF;
        color: #ffffff;
        transition: all 0.4s ease;
      }
    }
    .issue-detail-wrapper{
      padding: 0 25px;
      .issue-detail-header{
        position: fixed;
        width: 365px;
        background-color: #ffffff;
        z-index: 999;
      }
      .row{
          .back-btn{
            padding: 8px 10px 7px;
            background: #f3f3f3;
            border-radius: 3px;
            font-family: $ibmplexRegular;
            font-size: 13px;
            color: #000000;
            line-height: 15px;
            text-align: left;
            margin-bottom: 10px;
            display: inline-block;
            text-transform: uppercase;
            i{
              position: relative;
              right: 3px;
              font-size: 14px;
              top: 1px;
            }
          }
      }
      .reviewer-issue-drawer{
        width: 100%;
        margin: 0;
        right: 0;
        top: 40px;
        >div{
          padding: 0;
          ul{
            li{
              padding: 15px 10px;
            }
          }
        }
        &.parent-issue{
          ul{
            li{
              margin-left: -25px;
              margin-right: -25px;
              margin-top: 35px;
            }
          }
        }
      }
      .associated-comments{
        padding-left: 40px;
      }
      .add-comments{
        position: fixed;
        bottom: 30px;
        width: 400px;
        right: 15px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        background: #fff;
        z-index: 99999;
        form{
          width: 370px;
        }
      }
    }
  }
}

// popover info
.lead-info-pop{
  img{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 6px;
  }
  .popover-div{
    &:first-child{
      padding-top:0;

    }
  }
}

.asset-info-pop{
  .popover-div{
    &:first-child{
      padding-top:0;

    }
  }

}


// tracker block filter scss
.um-close-icon{
  position: absolute;
  right: 8px;
  top: 8px;
  z-index:9;
  cursor: pointer;
}

.tracker-filter-block{
  display: flex;
  justify-content: right;
  margin-bottom:15px;
  .close-icon{
    width:12px;
  }
  li{

  }

}

// technical module
.user-detail-modal{
  .user-profile-pic{
    margin-bottom:10px;
    p{
      font-family: $ibmplexSemiBold;
      font-size: 16px;
      color: #3f8dfa;
      text-align: center;
      padding: 7px 0 3px;
    }
    img{
      width: 100px;
      border-radius: 50%;
      height: 100px;
      object-fit: cover;
      margin: 0 auto;
      display: block;
    }
  }
  ul{
    display: inline-block;
    width: 100%;
    background: #f1f1f1;
    padding: 15px;
    border-radius: 6px;
    label{
      font-family: $ibmplexRegular;
      font-size: 12px;
      color: #7a7a7a;
      text-align: left;
      font-weight: 100;
      top: -3px;
      position: relative;
    }
    p{
      font-family: $ibmplexRegular;
      font-size: 12px;
      color: #000000;
    }
    li{
      .first-block{
        width:50%;
      }
      .second-block{
        width:50%;
      }
    }
  }

}


.auto-renamig-block{
  .ml-actions{
    width: 15px;
  }
  .no-records-found{
    margin: 0px;
  }
  .notice-footer{
    position: fixed;
    bottom: 5px;
    right: 0px;
    left: 285px;
    font-size: 14px;
    color: #8d9faa;
    background: #fff;
    padding: 15px 25px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,.61);
    border: 1px solid #8d9faa;
    bottom: 0;
    .btn-primary{
      font-size: 12px;
      margin-left: 20px;
      padding: 5px 35px;
    }
  }
  .user-logo{
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }
  .pagination{
    background: transparent!important;
  }
  h4{
    font-size: 16px;
    color: #bd10e0;
    font-family: $ibmplexRegular;
    margin-bottom:12px;
  }



    .to-be-reviewed{
      margin-bottom: 60px;
      .list-inline{
        padding: 0px;
      }
      .back-nav{
        img{
          margin:0 4px 15px 0;
          display: inline-block;

        }
        span{
          position: relative;
          top: -7px;
        }
      }
    }
    .to-be-reviewed, .reviewed{
      h4{

      }
      ul{
        display: flex;
        flex-flow: nowrap;
        background: #fff;
        padding: 8px 3px 15px;
        margin:0 0 15px;
        li{
          .user-block{
            padding: 4px 0;
            img{
              width: 35px;
              height: 35px;
              border-radius: 50%;
              float: left;
              margin-right: 8px;
            }
          }
          label{
            cursor: pointer;
            font-size: 12px;
            font-family: $ibmplexRegular;
            color: #a1a1a1;

          }
          p{
            font-family: $ibmplexRegular;
            font-size: 14px;
            color: #000;
          }
          .processed-img{
              width: 16px;
              margin-right:6px;

          }
        }
      }
    }

    .reviewed{
      ul{
        background: #E6E6E6;
        li{

        }
      }
    }
}

// processing pop over
.processing-popup{
  p{
    font-size: 14px;
    font-family: $ibmplexRegular;
    color: #a1a1a1;
  }
  a{
    cursor: pointer;
    @extend %primary-botton;
    padding: 0px 4px;
    color: #fff !important;
    margin-top: 10px;
    display: inline-block;
    font-size: 12px !important;
  }
}
