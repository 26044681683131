
@import '../../../assets/styles/setting';

.mr-calc-sidebar{
  position: fixed;
  z-index: 0;
  padding:0 0 90px;
  background-image:linear-gradient(-180deg, #6538db 0%, #0a56a8 100%);
  width: 200px;
  top: 50px;
  left: 70px;
  bottom: 0;
  overflow: auto;
  &::-webkit-scrollbar{
    display: none;
  }
  &.contract-side-bar{
    top: 94px;
  }
  .mr-calc-heading{
    padding-top:35px;
    background: rgba(0,0,0,0.2);
    .back-icon{
      display: block;
      width: 9px;
      margin: 0 15px 3px;
    }
    .contract-back-nav{
      font-size: 10px;
      color: #F0A4FF;
      font-family: $ibmplexRegular;
      padding-left: 20px;
    }
    h3{
      padding: 0 15px 30px;
      font-family: $ibmplexRegular;
      font-size:20px;
      color:$color_fff;
    }
  }
  .ct-back-icon{
    display: block;
    padding-bottom: 15px;
  }

  h5{
    font-size: 16px;
    color: #F0A4FF !important;
    font-family: $ibmplexRegular;
    padding-left: 16px;
    margin: 30px 0 10px 0;
  }
  ul{
    li{
      a{
        display:block;
        padding-left: 32px;
        cursor: pointer;
        font-family: $ibmplexRegular;
        font-size:12px;
        color:$color_fff !important;
        padding: 10px 15px;
        &:hover{
          color:$color_fff !important;
        }
        &.active{
          background: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
          background: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
          background: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
            color:$color_fff;
            &:hover{
              color:$color_fff;
            }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body{
    .mr-calc-sidebar{
      left: 60px !important; 
    }
  }
}
